import { Box, styled, Typography } from '@mui/material'

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(4),
}))

export const CurrentFileWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: [theme.spacing(2), theme.spacing(4)].join(' '),
  backgroundColor: theme.palette.grey[200],
  borderRadius: theme.shape.borderRadius,
}))

export const CurrentFileText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
}))

CurrentFileText.defaultProps = {
  variant: 'subtitle2',
}

export const InputWrapper = styled(Box)<{
  focused?: boolean
}>(({ theme, focused }) => ({
  transition: theme.transitions.create(['border', 'color'], {
    duration: theme.transitions.duration.short,
  }),
  width: '100%',
  display: 'flex',
  gap: theme.spacing(4),
  alignItems: 'center',
  justifyContent: 'center',
  padding: [theme.spacing(4), theme.spacing(4)].join(' '),
  border: `dashed 3px`,
  borderRadius: (theme.shape.borderRadius as number) * 2,
  borderColor: focused ? theme.palette.primary.main : theme.alternativePalette.dark.main,
  color: focused ? theme.palette.primary.main : theme.alternativePalette.dark.main,
  cursor: 'pointer',

  '&:hover': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}))

export const TextsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

export const Title = styled(Typography)(() => ({
  color: 'inherit',
  textTransform: 'capitalize',
}))

export const Description = styled(Typography)(() => ({
  color: 'inherit',
}))

export const IconsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}))
