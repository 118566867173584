import { FC, useCallback } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface VinciExitDialogProps {
  message?: string
  open: boolean
  onConfirm?: () => void
  onReject?: () => void
  onClose?: () => void
}

export const VinciExitDialog: FC<VinciExitDialogProps> = ({
  open,
  onConfirm,
  onReject,
  onClose,
  message = 'There are unsaved changes, are you sure you want to leave?',
}) => {
  const handleClose = useCallback(() => onClose?.(), [onClose])

  const { t } = useTranslation()

  const handleAct = useCallback(
    (action?: () => void) => {
      action?.()
      handleClose()
    },
    [handleClose],
  )

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>{t(message)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {onReject && <Button onClick={() => handleAct(onReject)}>Cancel</Button>}
        <Button onClick={() => handleAct(onConfirm)}>{t('Confirm')}</Button>
      </DialogActions>
    </Dialog>
  )
}
