import { FormControl, Icon, IconButton, Tooltip, Typography } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { capitalize } from 'lodash'
import { useConfirm } from 'material-ui-confirm'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { VinciButton, VinciInput, VinciPhoneInput } from '~/components'
import { formatSize } from '~/utils/formatSize'
import { truncateString } from '~/utils/truncateString'
import { VinciAccordion } from '../VinciAccordion/VinciAccordion'
import VinciUploadArea, { TVinciUploadAreaRef } from '../VinciUploadArea/VinciUploadArea'
import * as S from './SupportForm.styles'

interface SupportFormValues {
  email?: string;
  phone_number?: string;
  name?: string;

  subject: string
  description: string;
}

interface ISupportFormProps {
  attachments: File[];
  handleAddAttachments: (files: File[]) => void
  handleRemoveAttachments: (name: string) => void
  user: boolean
}

export const SupportForm = ({ attachments, handleAddAttachments, handleRemoveAttachments, user }: ISupportFormProps) => {
  const formik = useFormikContext<SupportFormValues>()
  const uploadRef = useRef<TVinciUploadAreaRef>(null)
  const confirm = useConfirm()

  const { t } = useTranslation()

  return (
    <S.Wrapper>
      {!user && <>
        <VinciInput
          name='name'
          label={t('Name')}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          fullWidth
        />
        <VinciInput
          name='email'
          label={t('Email')}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          fullWidth
        />

        <FormControl sx={{ my: 2 }} fullWidth>
          <Field
            component={VinciPhoneInput}
            name='phone_number'
            label={`${t('Phone Number')} *`}
            fullWidth
          />
        </FormControl>
      </>
      }

      <VinciInput
        name='subject'
        label={t('Subject')}
        value={formik.values.subject}
        onChange={formik.handleChange}
        error={formik.touched.subject && Boolean(formik.errors.subject)}
        helperText={formik.touched.subject && formik.errors.subject}
        fullWidth
      />

      <VinciInput
        name='description'
        label={t('Description')}
        value={formik.values.description}
        onChange={formik.handleChange}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
        multiline
        rows={6}
        fullWidth
      />

      <VinciAccordion
        header={{ title: t('Attachments') }}
        styles={{
          wrapperSx: {
            boxShadow: 'none',
            borderRadius: '0 !important',
            borderTop: '0 !important',
          }
        }}
      >
        <VinciUploadArea
          ref={uploadRef}
          onAcceptFiles={(files) => handleAddAttachments(files)}
          allowedMimes={{
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/x-png': ['.png'],
            'image/png': ['.png'],
            'application/pdf': ['.pdf'],
            'application/x-pdf': ['.pdf'],
          }}
        >
          <S.FilesWrapper>
            {attachments?.map((attachment, index) => {
              const itemDetails: string[] = [
                attachment.name,
                capitalize(attachment.type),
                formatSize(attachment.size),
              ]

              return (
                <S.FileWrapper key={`${attachment.name}-${index}`}>
                  <S.FileInfo>
                    <Tooltip title={itemDetails.join(', ')}>
                      <Typography>{truncateString(attachment.name, 25)}</Typography>
                    </Tooltip>
                    <Tooltip title={itemDetails.join(', ')}>
                      <Icon
                        fontSize='small'
                        color='action'
                        sx={{
                          float: 'right',
                          cursor: 'pointer',
                          marginTop: '-2px',
                        }}
                      >
                        help
                      </Icon>
                    </Tooltip>
                  </S.FileInfo>
                  <S.FileActions>

                    <IconButton
                      onClick={() =>
                        confirm({
                          description: t('Are you sure you want to remove this attachment?'),
                        }).then(() => (handleRemoveAttachments(attachment.name)))
                      }
                    >
                      <Icon color='error' fontSize='small'>
                        delete
                      </Icon>
                    </IconButton>
                  </S.FileActions>
                </S.FileWrapper>
              )
            })}

            {!attachments.length && <S.NoFilesText>{t('No files attached.')}</S.NoFilesText>}

          </S.FilesWrapper>

          <VinciButton
            sx={{ margin: '0 auto' }}
            startIcon={<Icon>upload</Icon>}
            onClick={() => uploadRef.current?.openDialog()}
          >
            {t('Upload')}
          </VinciButton>
        </VinciUploadArea>
      </VinciAccordion>
    </S.Wrapper>
  )
}
