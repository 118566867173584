import { useEffect, useState } from 'react'

import _ from 'lodash'

interface Size {
  width: number | undefined
  height: number | undefined
}

export const useWindowSize = () => {
  const hasWindow = typeof window !== 'undefined'

  const [windowSize, setWindowSize] = useState<Size>({
    width: hasWindow ? window.innerWidth : undefined,
    height: hasWindow ? window.innerHeight : undefined,
  })

  useEffect(() => {
    const debouncedHandleResize = _.debounce(function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }, 1000)

    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize)
  })

  return windowSize as Size
}
