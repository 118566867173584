import { AddTask, BugReport, Help } from "@mui/icons-material";
import React, { useReducer, useState } from "react";
import { useTranslation } from 'react-i18next';
import FAQModal from "../FAQModal/FAQModal";
import { SupportModal } from "../SupportModal/SupportModal";
import { VinciButton } from "../VinciButton";
import * as S from './SupportSection.styles';

export type TSupportType = 'request_feature' | 'report_bug'

const SupportSection = () => {
  const [supportType, setSupportType] = useState<TSupportType | null>(null)
  const { t } = useTranslation();
  const [isFAQOpen, toggleIsFAQOpen] = useReducer((state) => !state, false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <S.SupportMenuButtonWrapper>
      <VinciButton
        variant="text"
        id="help-button"
        aria-haspopup="true"
        aria-controls={open ? 'help-center-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="large"
        startIcon={<S.SupportSectionIcon />}
      >
        {t('Help Center')}
      </VinciButton>

      <S.SupportMenu
        id="help-center-menu"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        MenuListProps={{ 'aria-labelledby': 'help-button' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <S.SupportMenuList>
          <S.SupportMenuItem onClick={toggleIsFAQOpen}>
            <S.SupportMenuIcon>
              <Help />
            </S.SupportMenuIcon>
            <S.SupportMenuText>
              {t('FAQ')}
            </S.SupportMenuText>
          </S.SupportMenuItem>

          <S.SupportMenuItem onClick={() => setSupportType('report_bug')}>
            <S.SupportMenuIcon>
              <BugReport />
            </S.SupportMenuIcon>
            <S.SupportMenuText>
              {t('Report a Bug')}
            </S.SupportMenuText>
          </S.SupportMenuItem>

          <S.SupportMenuItem onClick={() => setSupportType('request_feature')}>
            <S.SupportMenuIcon>
              <AddTask />
            </S.SupportMenuIcon>
            <S.SupportMenuText>
              {t('Suggest a Feature')}
            </S.SupportMenuText>
          </S.SupportMenuItem>

        </S.SupportMenuList>
      </S.SupportMenu>

      {supportType && <SupportModal type={supportType} onClose={() => setSupportType(null)} />}
      {isFAQOpen && <FAQModal isOpen={isFAQOpen} onClose={toggleIsFAQOpen} />}
    </S.SupportMenuButtonWrapper>
  )
}

export default SupportSection;
