export const palette = {
  primary: {
    light: '#0073AE',
    main: '#0073AE',
    dark: '#0073AE',
    contrastText: '#fff',
  },
  secondary: {
    light: '#42424a',
    main: '#242424',
    dark: '#131314',
    contrastText: '#fff',
  },
  background: {
    paper: '#fff',
    default: '#f0f2f5',
  },
  grey: {
    50: '#fafafa',
    100: '#f8f9fa',
    200: '#f0f2f5',
    300: '#dee2e6',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#6c757d',
    700: '#495057',
    800: '#343a40',
    900: 'rgb(123, 128, 154)',
  },

  common: {
    white: '#ffffff',
    black: '#000000',
  },

  info: {
    main: '#0073AE',
    light: '#0073AE',
    dark: '#0073AE',
    contrastText: '#000',
  },

  success: {
    main: '#4CAF50',
  },

  warning: {
    main: '#fb8c00',
  },

  error: {
    main: '#F44335',
  },
}
