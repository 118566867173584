import { api } from '~/data/api'
import { IReport, IReportDiscrepancyPhoto, IReportPhoto, TPhotoSection } from '~/utils/types'

export interface IGetReportPhotos {
  reportId: string
  category?: string
  section?: TPhotoSection[]
  get_url?: boolean
  discrepancyId?: string
}

export const getReportPhotos = async (props: IGetReportPhotos): Promise<IReportPhoto[]> => {
  const response = await api().get(`/report/${props.reportId}/photos`, {
    params: {
      photo_category: props.category,
      photo_section: JSON.stringify(props.section),
      get_url: props.get_url && 'true',
      discrepancy_id: props.discrepancyId,
    },
  })

  return response.data
}

export interface IUploadReportPhotos {
  reportId: string
  category: string
  section: string
  photos: File[]
  getUrl?: boolean
  discrepancyId?: string
  location?: string
  type?: string

  // Events
  onUploadProgress?: (event: ProgressEvent) => void
}

export type IUploadReportPhotosResponse = IReportDiscrepancyPhoto[]

export const uploadReportPhotos = async (
  props: IUploadReportPhotos,
): Promise<IUploadReportPhotosResponse> => {
  if (!props.photos.length) return []

  const formData = new FormData()
  formData.append('photo_category', props.category)
  formData.append('photo_section', props.section)
  formData.append('initial_location', props.location || '')
  formData.append('initial_type', props.type || '')
  props.getUrl && formData.append('get_url', '1')
  props.discrepancyId && formData.append('discrepancy_id', props.discrepancyId)
  props.photos.forEach((photo, i) => {
    formData.append(i.toString(), photo)
  })

  const response = await api().post(`/report/${props.reportId}/upload_photo`, formData, {
    onUploadProgress: props.onUploadProgress,
  })

  return response.data
}

export interface IUpdateReportPhoto {
  reportId: string
  photoId: string
  section?: TPhotoSection[]
  photo?: File
}

export const updateReportPhoto = async (
  props: IUpdateReportPhoto,
): Promise<IReportPhoto | false> => {
  if (!props.photo) return false

  const formData = new FormData()
  formData.append('file', props.photo)

  const response = await api().patch(`/report/${props.reportId}/photo/${props.photoId}`, formData)

  return response.data as IReportPhoto
}

export interface IDeleteReportPhoto {
  reportId: string
  photoId: string
}

export const deleteReportPhoto = async (props: IDeleteReportPhoto): Promise<boolean> => {
  const response = await api().delete(`/report/${props.reportId}/photo/${props.photoId}`)

  return 200 <= response.status && response.status <= 299
}

export interface IGetReportPhotoUrl {
  reportId: string
  photoId: string
  original?: boolean
}

export const getReportPhotoUrl = async (props: IGetReportPhotoUrl): Promise<string> => {
  const response = await api().get(`/report/${props.reportId}/photo/${props.photoId}/download`, {
    params: {
      original: props.original && 'true',
    },
  })

  return response.data as string
}
export interface IGetReportPhotoByID {
  reportId: string
  photoId: string
}

export const getReportPhotoByID = async (props: IGetReportPhotoByID): Promise<IReportPhoto> => {
  const response = await api().get(`/report/${props.reportId}/photo/${props.photoId}`)

  return response.data as IReportPhoto
}
export interface IGetReportPhotoCategoriesByReportID {
  reportId: string
}

export const getReportPhotoCategoriesByReportID = async (
  props: IGetReportPhotoCategoriesByReportID,
): Promise<IReport['photo_categories']> => {
  const response = await api().get(`/report/${props.reportId}/photo_categories`)

  return response.data as IReport['photo_categories']
}

interface ICountPhotosResponseItem {
  _id: string
  count: number
}
export const countReportPhotos = async (reportId: string): Promise<ICountPhotosResponseItem[]> => {
  const response = await api().get(`/report/${reportId}/photos/count`)

  return response.data as ICountPhotosResponseItem[]
}

export interface IConflictedItem {
  _id: string
  category: string
  discrepancy_id: string
  file_key: string
  report: string
  section: string
}

export const checkImageNameConflict = async (files: any) => {
  const response = await api().post('/report/get_check_photo', files)

  return response.data as IConflictedItem[]
}
