import { SignalWifiBadOutlined } from '@mui/icons-material'
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  styled,
} from '@mui/material'

export const StickyWrapper = styled(Box)<{ shouldelevate?: string | null }>(
  ({ theme, shouldelevate }) => ({
    transition: theme.transitions.create(['background-color', 'box-shadow'], {
      duration: theme.transitions.duration.shorter,
    }),
    width: '100%',
    position: 'sticky',
    top: theme.spacing(4),
    left: 0,
    zIndex: shouldelevate ? 100 : 1,
    backgroundColor: shouldelevate ? 'white' : 'transparent',
    backdropFilter: 'blur(5px)',
    border: `1px solid ${shouldelevate ? 'rgb(0,0,0, .2)' : 'transparent'}`,
    boxShadow: shouldelevate
      ? 'rgb(255 255 255 / 90%) 0rem 0rem 0.0625rem 0.0625rem inset, rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem'
      : 'rgb(255 255 255 / 0%) 0rem 0rem 0.0625rem 0.0625rem inset, rgb(0 0 0 / 0%) 0rem 1.25rem 1.6875rem 0rem',
    borderRadius: '1rem',
    '& > div': {
      padding: shouldelevate
        ? [theme.spacing(2), theme.spacing(4)].join(' ')
        : [theme.spacing(2), 0].join(' '),
    },
  }),
)

export const Wrapper = styled(Box)(({ theme }) => ({
  transition: theme.transitions.create('padding', {
    duration: theme.transitions.duration.shorter,
  }),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const SupportProfileWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    '.username, .myProfileLabel, .languageSelector, .helpDeskLabel': {
      display: 'none',
    },
  },

  [theme.breakpoints.up('sm')]: {
    '.username, .myProfileLabel, .languageSelector, .helpDeskLabel': {
      display: 'inline-block',
    },
  },
}))
export const MenuButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  transition: theme.transitions.create('background', {
    easing: 'ease-in-out',
    duration: '200ms',
  }),

  '&:hover': { background: theme.palette.grey[300] },
}))

export const IconWrapper = styled(IconButton)(() => ({
  '& .material-icons, .material-icons-round': {
    color: 'inherit',
    fontSize: `2rem !important`,
  },

  '& .MuiTypography-root': {
    display: 'inline-block',
    lineHeight: 1.2,
    marginLeft: '0.25rem',
  },

  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

export const ProfileMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: '1px solid rgba(0,0,0,.05)',
    borderRadius: '.5rem',
    boxShadow:
      'rgb(255 255 255 / 90%) 0rem 0rem 0.0625rem 0.0625rem inset, rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem',
  },
  '& .MuiPaper-root > .MuiList-root': {
    padding: theme.spacing(2),
  },
}))
export const ProfileMenuList = styled(MenuList)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))
export const ProfileMenuItem = styled(MenuItem)(() => ({
  borderRadius: '.5rem',
}))
export const ProfileMenuIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.grey[900],
}))
export const ProfileMenuText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.grey[900],
}))

export const ToggleUIButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(4),
  cursor: 'pointer',
}))

export const OfflineAlertWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginLeft: theme.spacing(4),
  alignItems: 'center',
}))

export const OfflineAlertText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  '@keyframes blinker': {
    from: { opacity: 1 },
    '50%': { opacity: 0.5 },
    to: { opacity: 1 },
  },
  animationName: 'blinker',
  animationDuration: '2s',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',

  '&:after': {
    content: '""',
    animation: 'ellipsis steps(1,end) 2s infinite',
    '@keyframes ellipsis': {
      from: {
        content: '""',
      },
      '25%': {
        content: '"."',
      },
      '50%': {
        content: '".."',
      },
      '75%': {
        content: '"..."',
      },
      to: {
        content: '""',
      },
    },
  },
}))

OfflineAlertText.defaultProps = {
  variant: 'body2',
}

export const OfflineAlertIcon = styled(SignalWifiBadOutlined)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: 20,
}))
