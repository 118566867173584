import { Icon, SxProps, Typography } from '@mui/material'
import React, { FC, MouseEvent, ReactNode, Ref, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { VinciButton } from '../VinciButton'
import * as S from './VinciAccordion.styles'
import { AccordionButtons } from './components/AccordionButtons/AccordionButtons'

export interface IAccordionButton extends React.ComponentProps<typeof VinciButton> {
  caption?: string
  to?: string
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  tooltip?: string
  variant?: 'contained' | 'outlined' | 'text'
  color?: 'primary' | 'secondary' | 'error'
  icon?: string
}

export interface IVinciAccordionProps {
  readOnly?: boolean
  header?: {
    title: string
    description?: string | ReactNode
    highlightDescription?: boolean
    buttons?: IAccordionButton[]
  }
  children: ReactNode
  styles?: {
    wrapperSx?: SxProps
    summarySx?: SxProps
    descriptionSx?: SxProps
    contentSx?: SxProps
    titleSx?: SxProps
    buttonSx?: SxProps
  }
  unmountOnExit?: boolean
  mountOnEnter?: boolean
  defaultExpanded?: boolean
  expanded?: boolean
  headerSubtitleOpened?: string
  headerSubtitleClosed?: string
  onChange?: (event: SyntheticEvent<Element, Event>, expanded: boolean) => void | undefined
  ref?: Ref<HTMLDivElement>
}

export const VinciAccordion: FC<IVinciAccordionProps> = ({
  header,
  children,
  styles,
  expanded,
  onChange,
  headerSubtitleOpened = 'See More',
  headerSubtitleClosed = 'See More',
  unmountOnExit = true,
  mountOnEnter = false,
  defaultExpanded = false,
  readOnly = false,
}) => {
  const { t } = useTranslation()

  const handleToggle = (event: MouseEvent<HTMLButtonElement>) => {
    if (onChange) {
      onChange(event, !expanded)
    }
  }

  return (
    <S.Accordion
      sx={styles?.wrapperSx}
      TransitionProps={{
        unmountOnExit,
        mountOnEnter,
      }}
      defaultExpanded={defaultExpanded}
      disableGutters
      expanded={expanded}
      onChange={onChange}
    >
      {header && (
        <S.AccordionSummary
          sx={styles?.summarySx}
          expandIcon={!readOnly && <Icon fontSize='large'>expand_more</Icon>}
        >
          <S.AccordionTitle sx={styles?.titleSx}>{t(header.title)}</S.AccordionTitle>
          <S.AccordionDescription
            sx={styles?.descriptionSx}
            highlight={header.highlightDescription ? 'true' : 'false'}
            variant={header.highlightDescription ? 'h6' : undefined}
          >
            {header.description}
          </S.AccordionDescription>
          <S.AccordionActions>
            <AccordionButtons buttons={header.buttons || []} />
          </S.AccordionActions>
          {!readOnly && (
            <Typography
              onClick={handleToggle}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#B3B3B3 !important',
                fontWeight: 500
              }}
            >
              {expanded ? t(headerSubtitleOpened) : t(headerSubtitleClosed)}
            </Typography>
          )}
        </S.AccordionSummary>
      )}
      {!readOnly && <S.AccordionDetails sx={styles?.contentSx}>{children}</S.AccordionDetails>}
    </S.Accordion>
  )
}
