import { Icon, Tooltip } from '@mui/material'
import { FC, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { VinciButton } from '~/components/VinciButton'
import { IAccordionButton } from '../../VinciAccordion'

interface IAccordionButtonsProps {
  buttons: IAccordionButton[]
}

export const AccordionButtons: FC<IAccordionButtonsProps> = ({ buttons }) => {
  return (
    <>
      {buttons?.map((button, i) =>
        button.tooltip ? (
          <Tooltip title={button.tooltip} key={i}>
            {button.to ? (
              <Link to={button.to}>
                <VinciButton
                  color={button.color || 'primary'}
                  variant={button.variant || 'outlined'}
                  startIcon={button.icon && <Icon>{button.icon}</Icon>}
                  {...button}
                >
                  {button.caption}
                </VinciButton>
              </Link>
            ) : (
              <VinciButton
                color={button.color || 'primary'}
                variant={button.variant || 'outlined'}
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                  button.onClick?.(e)
                }}
                key={i}
                startIcon={button.icon && <Icon>{button.icon}</Icon>}
                {...button}
              >
                {button.caption}
              </VinciButton>
            )}
          </Tooltip>
        ) : button.to ? (
          <Link to={button.to} key={i}>
            <VinciButton
              color={button.color || 'primary'}
              variant={button.variant || 'outlined'}
              {...button}
            >
              {button.icon && <Icon>{button.icon}</Icon>} {button.caption}
            </VinciButton>
          </Link>
        ) : (
          <VinciButton
            color={button.color || 'primary'}
            variant={button.variant || 'outlined'}
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              button.onClick?.(e)
            }}
            key={i}
            sx={{ height: 'fit-content' }}
            startIcon={button.icon && <Icon>{button.icon}</Icon>}
            {...button}
          >
            {button.caption}
          </VinciButton>
        ),
      )}
    </>
  )
}
