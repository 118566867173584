import { useWindowSize } from './useWindowSize'

export const useIsDevice = () => {
  const windowWidth = useWindowSize().width
  const isMobile = windowWidth ? windowWidth < 720 : false
  const isTablet = !isMobile ? (windowWidth ? windowWidth < 1024 : false) : false
  const isDesktop = !isMobile && !isTablet

  return {
    isMobile,
    isTablet,
    isDesktop,
  }
}
