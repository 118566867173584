import { Skeleton, SxProps } from '@mui/material'
import { FC, ReactNode, useEffect, useState } from 'react'

import { getReportPhotoCategoryImage } from '~/modules/inspection/data/api'
import { getReportPhotoUrl } from '~/modules/inspection/pages/FillReport/Photos/data/api'
import { BackdropLoader } from '../BackdropLoader/BackdropLoader'
import { VinciModal } from '../VinciModal'
import * as S from './VinciPlaceholderImage.styles'

interface IVinciPlaceholderImageProps {
  url: string
  alt?: string
  placeholderElement?: ReactNode
  defaultPlaceholderSize?: {
    height?: number | string
    width?: string | number
  }
  imageSx?: SxProps
  fadeInDuration?: number
  onLoad?: (src: string) => void
  category?: 'aircraft' | 'report' | 'photo_category'
  photoCategoryUUID?: string
  photoId?: string
  reportId?: string
}

export const VinciPlaceholderImage: FC<IVinciPlaceholderImageProps> = ({
  url,
  alt,
  placeholderElement,
  defaultPlaceholderSize,
  imageSx,
  fadeInDuration,
  onLoad,
  category,
  photoCategoryUUID,
  photoId,
  reportId,
}) => {
  const [loading, setLoading] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [originalPhotoUrl, setOriginalPhotoUrl] = useState<string | undefined>(undefined)
  const [originalLoading, setOriginalLoading] = useState<boolean>(false)

  useEffect(() => {
    const img = new Image()
    img.src = url

    img.onload = () => {
      setLoading(false)
      onLoad?.(url)
    }
  }, [url, onLoad])

  const handleClick = async () => {
    try {
      if (!category) return
      setOriginalLoading(true)

      let photoUrl = ''

      switch (category) {
        case 'report': {
          photoUrl = await getReportPhotoUrl({
            photoId: photoId ?? '',
            reportId: reportId ?? '',
            original: true,
          })

          break
        }
        case 'photo_category': {
          photoUrl = await getReportPhotoCategoryImage({
            report_id: reportId ?? '',
            category_uuid: photoCategoryUUID ?? '',
            original: true,
          })
          break
        }
        default:
          return
      }

      setOriginalLoading(false)
      setOriginalPhotoUrl(photoUrl)
      setModalOpen(true)
    } catch (err) {
      console.log(err)
      if (originalLoading) {
        setOriginalLoading(false)
      }
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  if (loading)
    return (
      <>
        {placeholderElement || (
          <Skeleton
            variant='rounded'
            width={defaultPlaceholderSize?.width || '100%'}
            height={defaultPlaceholderSize?.height || 300}
          />
        )}
      </>
    )

  return (
    <>
      <S.Image
        onClick={handleClick}
        sx={{
          ...(category && { cursor: 'pointer' }),
          ...imageSx,
        }}
        duration={fadeInDuration}
        src={url}
        alt={alt}
      />
      {originalLoading && <BackdropLoader loaderType='linear' />}
      {modalOpen && originalPhotoUrl && (
        <VinciModal
          open
          onClose={handleCloseModal}
          sx={{
            '& .MuiPaper-root': {
              maxWidth: 'unset',
            },
          }}
          contentSx={{
            maxHeight: '100%',
            overflowY: 'hidden',
          }}
        >
          <a href={originalPhotoUrl} target='_blank'>
            <S.Image
              sx={{
                ...imageSx,
                maxWidth: 'none',
                width: '100%',
                height: '90vh',
                objectFit: 'contain',
              }}
              duration={fadeInDuration}
              src={originalPhotoUrl}
              alt={alt}
            />
          </a>
        </VinciModal>
      )}
    </>
  )
}
