import { Button, styled } from '@mui/material'
import { VinciButtonProps } from '~/components'

export const Wrapper = styled(Button)<VinciButtonProps>(
  ({ theme, circular, variant, color = 'primary', size, iconOnly }) => {
    const smallSizeStyles = () => ({
      padding: '0.625rem 1.125rem',
      fontWeight: 700,
      fontSize: '0.75rem',
      letterSpacing: '0.02857rem',
    })

    const containedStyles = () => {
      const backgroundColor = `linear-gradient(195deg, ${theme.palette[color].light}, ${theme.palette[color].main})`
      const focusedBackgroundColor = `linear-gradient(195deg, ${theme.palette[color].light}, ${theme.palette[color].main})`
      const textColor = theme.palette[color].contrastText
      const boxShadow = theme.shadows[1]
      const hoveredBoxShadow = theme.shadows[2]

      return {
        background: backgroundColor,
        color: textColor,
        boxShadow,
        padding: '0.5625rem 1.5rem',
        '&:disabled': {
          color: 'rgba(255,255,255,.5)',
        },
        '&:hover:not(:disabled)': {
          boxShadow: hoveredBoxShadow,
        },
        '&:focus:not(:hover)': {
          background: focusedBackgroundColor,
        },
      }
    }

    const outlinedStyles = () => {
      const backgroundColor = 'transparent'
      const textColor = theme.palette[color]?.main
      const hoveredTextColor = theme.palette[color]?.light

      return {
        transition: `${theme.transitions.create(['background', 'color', 'border-color'])}`,
        background: backgroundColor,
        color: textColor,
        borderRadius: '0.5rem',
        padding: '0.5625rem 1.5rem',
        '&:hover': {
          background: backgroundColor,
          color: hoveredTextColor,
          borderColor: hoveredTextColor,
        },
      }
    }

    const circularStyles = () => ({
      borderRadius: 9999,
      minWidth: 0,
    })

    const iconOnlyStyles = () => {
      let sizeValue = '2.375rem'

      if (size === 'small') {
        sizeValue = '1.5875rem'
      } else if (size === 'large') {
        sizeValue = '3.25rem'
      }

      let paddingValue = `0.6875rem 0.6875rem 0.625rem`

      if (size === 'small') {
        paddingValue = '0.28125rem'
      } else if (size === 'large') {
        paddingValue = '1rem'
      }

      return {
        width: sizeValue,
        minWidth: sizeValue,
        height: sizeValue,
        minHeight: sizeValue,
        padding: paddingValue,

        '& .material-icons': {
          marginTop: 0,
        },

        '&:hover, &:focus, &:active': {
          transform: 'none',
        },
      }
    }

    const defaultStyles = () => ({
      display: 'flex',
      alignItems: 'center',
    })

    return {
      ...defaultStyles(),
      ...(!variant && containedStyles()),
      ...(variant === 'contained' && containedStyles()),
      ...(variant === 'outlined' && outlinedStyles()),
      ...(size === 'small' && smallSizeStyles()),
      ...(circular && circularStyles()),
      ...(iconOnly && iconOnlyStyles()),
    }
  },
)
