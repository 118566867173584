import { Box, Breadcrumbs as MUIBreadcumbs, styled, Typography } from '@mui/material'

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: theme.spacing(16),
}))

export const Breadcumbs = styled(MUIBreadcumbs)(({ theme }) => ({
  '& .MuiBreadcrumbs-separator': {
    color: theme.palette.grey[600],
  },
}))

export const IconWrapper = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 300,
  color: theme.alternativePalette.dark.main,
  opacity: 0.5,
  lineHeight: 0,
  '& span': {
    fontSize: '1rem',
  },
}))

export const BreadcumbItem = styled(Typography)<{ active?: string }>(({ theme, active }) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  textTransform: 'capitalize',
  color: theme.alternativePalette.dark.main,
  opacity: active ? 1 : 0.5,
  lineHeight: 0,
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: 1.625,
  fontWeight: 'bold',
  textTransform: 'capitalize',
  color: theme.alternativePalette.dark.main,
}))
