import { useQuery } from 'react-query'
import { IUser } from '~/utils/types'
import { api } from './api'

export const getUserPermissions = async (): Promise<string[]> => {
  const response = await api().get(`list-permissions`)
  return response.data as string[]
}

export const useGetUserPermissions = (user: IUser | null) => {
  return useQuery({
    queryKey: ['user_permissions', user?._id],
    queryFn: user ? getUserPermissions : () => Promise.resolve([]),
    staleTime: Infinity,
  })
}

interface IGetResourcesWithPermission {
  action: string
  resource: string
}

export const getResourcesWithPermission = async (
  props: IGetResourcesWithPermission,
): Promise<string[]> => {
  const response = await api().get(`list-permissions`, {
    params: {
      action: props.action,
      resource: props.resource,
    },
  })

  return response.data
}

export const useGetResourcesWithPermission = (props: IGetResourcesWithPermission) => {
  return useQuery({
    queryKey: ['resources_with_permission', props.action, props.resource],
    queryFn: () => getResourcesWithPermission(props),
    staleTime: Infinity,
  })
}

type CheckPermissionProps = {
  action?: string
  resource?: string
}

type CheckPermissionResponse = boolean

export const checkPermission = async ({
  action,
  resource,
}: CheckPermissionProps): Promise<CheckPermissionResponse> => {
  const params = new URLSearchParams()
  if (action) params.append('action', action)
  if (resource) params.append('resource', resource)
  const response = await api().get(`check-permission?${params.toString()}`)
  return response.data?.has_permission
}

// TODO: Replace this
export const useCheckPermission = (props: CheckPermissionProps) => {
  return useQuery({
    queryKey: ['check_permission', props],
    queryFn: () => checkPermission(props),
    staleTime: Infinity,
  })
}
