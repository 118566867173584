import { theme } from '~/styles/theme'

export const containedStyles = () => {
  const color = 'primary'
  const backgroundColor = `linear-gradient(195deg, ${theme.palette[color].light}, ${theme.palette[color].main})`
  const focusedBackgroundColor = `linear-gradient(195deg, ${theme.palette[color].light}, ${theme.palette[color].main})`
  const textColor = theme.palette[color].contrastText
  const boxShadow = theme.shadows[1]
  const hoveredBoxShadow = theme.shadows[2]

  return {
    background: backgroundColor,
    color: textColor,
    boxShadow,
    '&:hover:not(:disabled)': {
      boxShadow: hoveredBoxShadow,
    },
    '&:focus:not(:hover)': {
      background: focusedBackgroundColor,
    },
  }
}

export const outlinedStyles = () => {
  const color = 'primary'
  const backgroundColor = 'transparent'
  const textColor = theme.palette[color]?.main
  const hoveredTextColor = theme.palette[color]?.light

  return {
    transition: `${theme.transitions.create(['background', 'color', 'border-color'])}`,
    background: backgroundColor,
    color: textColor,
    borderRadius: '0.5rem',
    '&:hover': {
      background: backgroundColor,
      color: hoveredTextColor,
      borderColor: hoveredTextColor,
    },
  }
}
