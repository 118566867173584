import { FC, ReactNode, forwardRef, useCallback, useImperativeHandle } from 'react'

import { Upload } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { formatSize } from '~/utils/formatSize'
import * as S from './VinciUploadArea.styles'

interface IVinciUploadAreaProps {
  children: ReactNode
  overlayContent?: ReactNode
  onAcceptFiles?: (files: File[]) => void
  maxFiles?: number
  disabled?: boolean
  allowedMimes?: {
    [mimetype: string]: string[]
  }
  maxSizeAllowed?: number
}

export type TVinciUploadAreaRef = {
  openDialog: () => void
}

export const VinciUploadArea = forwardRef<TVinciUploadAreaRef, IVinciUploadAreaProps>(
  function VinciUploadArea(props, ref) {
    const {
      children,
      overlayContent,
      onAcceptFiles,
      allowedMimes,
      maxFiles,
      maxSizeAllowed,
      disabled = false,
    } = props

    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        if (!acceptedFiles.length || disabled) return

        if (maxSizeAllowed) {
          for (const file of acceptedFiles) {
            if (file.size > maxSizeAllowed) {
              toast.warning(
                `One of the uploaded files exceeded the size limit of ${formatSize(
                  maxSizeAllowed,
                )}.`,
              )
              return
            }
          }
        }

        onAcceptFiles?.(acceptedFiles)
      },
      [disabled, maxSizeAllowed, onAcceptFiles],
    )

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      open: handleOpenDialog,
    } = useDropzone({
      onDrop,
      accept: allowedMimes,
      noClick: true,
      maxFiles,
    })

    useImperativeHandle(
      ref,
      () => ({
        openDialog() {
          if (disabled) return

          handleOpenDialog()
        },
      }),
      [disabled, handleOpenDialog],
    )

    return (
      <S.Wrapper {...getRootProps()}>
        <input {...getInputProps()} />
        <Overlay active={!disabled && isDragActive} content={overlayContent} />
        {children}
      </S.Wrapper>
    )
  },
)

interface IOverlayProps {
  active: boolean
  content?: ReactNode
  defaultText?: string
}
const Overlay: FC<IOverlayProps> = ({
  active,
  content,
  defaultText = 'You can drop files here',
}) => {
  const defaultOverlayContent = (
    <S.DefaultOverlayWrapper>
      <Upload />
      <Typography mt={3} variant='caption'>
        {defaultText}
      </Typography>
    </S.DefaultOverlayWrapper>
  )

  return (
    <S.OverlayWrapper
      sx={{
        opacity: active ? 1 : 0,
      }}
    >
      {content || defaultOverlayContent}
    </S.OverlayWrapper>
  )
}

export default VinciUploadArea
