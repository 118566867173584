import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { Typography } from '@mui/material'
import { Form, Formik, FormikProps, FormikValues } from 'formik'
import _ from 'lodash'
import { useConfirm } from 'material-ui-confirm'
import { useTranslation } from 'react-i18next'
import { VinciButton } from '~/components'
import { theme } from '~/styles/theme'
import VinciPrompt from '../VinciPrompt/VinciPrompt'
import * as S from './VinciFormModal.styles'

interface IForm {
  initialValues: FormikValues
  values?: FormikValues
  onSubmit: (values: FormikValues) => void | Promise<void>
  validationSchema?: any
  validateOnChange?: boolean
  validateOnBlur?: boolean
  validateOnMount?: boolean
}

type IFormTitle = {
  title?: string
  subtitle: string
}

interface SharedProps {
  title?: string | IFormTitle
  children: ReactNode
  deleteButton?: boolean
  onDelete?: (id: string) => Promise<void>
  onClose: () => void
  disableSubmitIfUnchanged?: boolean
  confirmButtonLabel?: string
  promptOnCloseIfChanged?: boolean
  alwaysPromptOnClose?: boolean
  extraAction?: ReactNode
}

type VinciFormModalProps = SharedProps & {
  opened: boolean
  form: IForm
  stopEventPropagationOnClick?: boolean
}

export const VinciFormModal: FC<VinciFormModalProps> = ({
  form,
  opened,
  onClose,
  promptOnCloseIfChanged = true,
  alwaysPromptOnClose = false,
  stopEventPropagationOnClick = true,
  ...props
}) => {
  const { t } = useTranslation()
  const confirm = useConfirm()
  const handleSubmit = useCallback(
    (values: FormikValues) =>
      confirm({
        description: t('Are you sure you want to save these changes?'),
      }).then(() => form.onSubmit(values)),
    [confirm, form, t],
  )
  const parsedForm = useMemo(() => ({ ...form, onSubmit: handleSubmit }), [form, handleSubmit])

  const [formChanged, setFormChanged] = useState(false)

  const handleClose = useCallback(() => {
    if (alwaysPromptOnClose || (promptOnCloseIfChanged && formChanged)) {
      confirm({
        description: t('There are unsaved changes, are you sure you want to leave?'),
      })
        .then(() => onClose?.())
        .catch(() => undefined)
    } else onClose?.()
  }, [alwaysPromptOnClose, promptOnCloseIfChanged, formChanged, onClose, confirm, t])

  return (
    <S.Wrapper
      open={opened}
      onClose={handleClose}
      onClick={(e) => {
        if (stopEventPropagationOnClick && e) {
          e.stopPropagation()
        }
      }}
    >
      <Formik {...parsedForm}>
        {(form) => (
          <InternalForm
            {...props}
            onClose={handleClose}
            form={form}
            onFormChangedChange={setFormChanged}
          />
        )}
      </Formik>
    </S.Wrapper>
  )
}

type InternalFormProps = SharedProps & {
  form: FormikProps<FormikValues>
  onFormChangedChange?: (changed: boolean) => void
}

const InternalForm = ({
  form,
  title = 'Form',
  children,
  deleteButton = true,
  onDelete,
  onClose,
  disableSubmitIfUnchanged = false,
  promptOnCloseIfChanged = true,
  alwaysPromptOnClose = false,
  confirmButtonLabel = 'Save',
  onFormChangedChange,
  extraAction,
}: InternalFormProps) => {
  const formChanged = useMemo(
    () => !_.isEqual(form.values, form.initialValues),
    [form.values, form.initialValues],
  )

  const shouldDisabledSubmit = useMemo(
    () => disableSubmitIfUnchanged && (Object.keys(form.errors).length > 0 || formChanged),
    [formChanged, form.errors, disableSubmitIfUnchanged],
  )

  const shouldPrompt = useMemo(
    () => alwaysPromptOnClose || (promptOnCloseIfChanged && formChanged),
    [alwaysPromptOnClose, promptOnCloseIfChanged, formChanged],
  )

  useEffect(() => {
    onFormChangedChange?.(formChanged)
  }, [onFormChangedChange, formChanged])

  const { t } = useTranslation()

  return (
    <Form>
      <VinciPrompt when={shouldPrompt} />
      {title && (
        <>
          <S.Title sx={{ paddingBottom: 0 }}>
            {typeof title === 'string' && title}
            {typeof title === 'object' && (
              <>
                <Typography variant='h6' component='div' sx={{ color: theme.palette.grey[900] }}>
                  {t(title.subtitle)}
                </Typography>
                <Typography variant='h4' component='span'>
                  {title.title}
                </Typography>
              </>
            )}
          </S.Title>
        </>
      )}
      <S.Content>{children}</S.Content>
      <S.ActionsWrapper>
        {extraAction || null}
        {deleteButton && onDelete && form.values._id && (
          <VinciButton
            color={'error'}
            variant={'text'}
            size={'small'}
            onClick={() => onDelete(form.values._id)}
          >
            {t('Delete')}
          </VinciButton>
        )}
        <VinciButton
          color={'primary'}
          variant={'outlined'}
          size={'small'}
          onClick={(e) => {
            onClose()
            e.stopPropagation()
          }}
        >
          {t('Cancel')}
        </VinciButton>
        <VinciButton
          color={'primary'}
          variant={'contained'}
          size={'small'}
          type={'submit'}
          disabled={shouldDisabledSubmit}
        >
          {t(confirmButtonLabel)}
        </VinciButton>
      </S.ActionsWrapper>
    </Form>
  )
}
