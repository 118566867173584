export interface IQuestion {
  title: string
  answer: string
}

export type TGroups = 'General Info' | 'Security' | 'Support'

interface IFAQ {
  [category: string]: IQuestion[]
}

export const FAQ: IFAQ = {
  'General Info': [
    {
      title: 'What is the general structure of the system?',
      answer:
        'The system consists of a desktop application accessed through a browser and an Android app (currently in development) for handling inspection photos. Inspectors answer checklist titles, upload records, and manage audits in the desktop application. The system is structured around checklist-report template pairs. Checklists are composed of various items, and report templates are programmed based on provided Word documents.',
    },
    {
      title: 'How is the company structure organized in the system?',
      answer:
        'The system has Subscribers and Non-Subscribers. Company types include Asset Managers, Audit Companies, and Operators.',
    },
    {
      title: 'What are the different user roles in the system?',
      answer:
        'User roles include: Managers - Can add operators, inspection companies, inspectors, supervisors, and guests; add assets; create audits and checklists; approve audits; generate and upload reports; and send audits back to inspectors. Supervisors - Can access audits they are marked as Lead Inspector for; create checklists; approve audits; generate and upload reports; and send audits back to inspectors. Inspectors - Can access and perform audits they are assigned to and send audits for supervisor approval. Guests - Have limited access to specific parts of an audit.',
    },
    {
      title: 'Can multiple inspectors work on the same audit simultaneously?',
      answer:
        'Currently, we can have more than one inspector per audit, but only one can work on the audit at the same time. We are still implementing simultaneous editing.',
    },
    {
      title: 'Will the system support multi-asset functionality in the future?',
      answer:
        'Yes, we are working on having the system support multi-asset functionality, which will allow for company/operator audits in the system.',
    },
    {
      title: 'How will asset component tracking be improved in future versions?',
      answer:
        'We are working on changes for version 3 to allow better tracking of asset components, as well as AD, SB, and STC.',
    },
    {
      title: 'What is the purpose of the Android app?',
      answer: 'The current Android app is designed for handling physical inspection photos.',
    },
    {
      title: 'How are checklists created in the system?',
      answer:
        'When creating a checklist, you choose the items you want to include, such as Audit Items, Asset Components, Tracking items (AD, SB, STC, and Repairs), Image Categories, and Records Categories.',
    },
    {
      title: 'How are report templates added to the system?',
      answer:
        'To add a report template, you send us a Word document, and we program it to be used by the system.',
    },
    {
      title: 'What is required to create an audit in the system?',
      answer:
        'To create an audit, you need a checklist in the system for that particular aircraft model.',
    },
  ],
  Security: [
    {
      title: 'What is the common security protocol for the system?',
      answer:
        "The system has dual authentication factor, it uses the 'User email' for verification code. Also, the platform logs off users after 30 minutes of inactivity or closing the browser window, requires password change at first login and every 90 days (without repeating older passwords), stores data in secure AWS servers in the USA, has audited software code, and provides audit logs for client review.",
    },
    {
      title: 'How secure is the data stored in the system?',
      answer:
        'All data is stored in the USA at AWS servers with all optional security appliances contracted, ensuring a high level of security.',
    },
    {
      title: 'Is the software code audited for security vulnerabilities?',
      answer:
        'Yes, the software code is audited, and the audit logs are available for client review.',
    },
  ],
  Support: [
    {
      title: 'How can I get help and support for the system?',
      answer:
        'You can contact us by email (zeh@eyesonasset.com) and by WhatsApp (+1 (805) 912-8428) and all Tickets will be responded to within 24 hours with either a fix or an action plan. Videos and AI-integrated user instructions are being developed and it will be available soon.',
    },
    {
      title: 'How long does it take to receive a response to a submitted ticket?',
      answer:
        'We have 24 hours to return a ticket opened by you with more information, either fixing the problem reported or informing you of the action plan.',
    },
  ],
}
