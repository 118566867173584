import { STATE_STORAGE_LOCATION } from './consts'

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STATE_STORAGE_LOCATION)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (state: unknown) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(STATE_STORAGE_LOCATION, serializedState)
  } catch (err) {
    console.log(err)
  }
}

export const loadStateFromSessionStorage = () => {
  try {
    const serializedState = sessionStorage.getItem(STATE_STORAGE_LOCATION)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveStateToSessionStorage = (state: unknown) => {
  try {
    const serializedState = JSON.stringify(state)
    sessionStorage.setItem(STATE_STORAGE_LOCATION, serializedState)
  } catch (err) {
    console.log(err)
  }
}
