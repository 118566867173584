import { ReactNode, createContext, useContext, useMemo, useState } from 'react'
import { IInspection } from '~/utils/types'

interface IManagerViewContextProps {
  lastAudits: IInspection[] | null
  setLastAudits(v: IInspection[] | null): void
}

const ManagerViewContext = createContext<IManagerViewContextProps | undefined>(undefined)
interface IProviderProps {
  children: ReactNode
}

export function ManagerViewProvider({ children }: Readonly<IProviderProps>) {
  const [lastAudits, setLastAudits] = useState<IInspection[] | null>(null)

  const providerValue = useMemo(
    () => ({
      lastAudits,
      setLastAudits,
    }),
    [lastAudits, setLastAudits], // Dependências
  )
  return <ManagerViewContext.Provider value={providerValue}>{children}</ManagerViewContext.Provider>
}

export function useManagerView() {
  const context = useContext(ManagerViewContext)
  if (!context) {
    throw new Error('useManagerViewContext must be used within a ManagerViewProvider')
  }
  return context
}
