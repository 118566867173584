import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const i18nConfig = {
  fallbackLng: 'en',
  defaultNS: 'translations',
  backend: {
    loadPath: (lng: string) =>
      `https://eyeson-i18n-us-east-1.s3.amazonaws.com/translations/locales/${lng}/translations.json`,
    request: async (
      options: { customHeaders: any },
      url: RequestInfo | URL,
      payload: any,
      callback: (arg0: unknown, arg1: { status: number; data?: any }) => void,
    ) => {
      try {
        const response = await fetch(url)
        if (!response.ok) {
          callback(response.statusText, { status: response.status })
          return
        }
        const data = await response.json()
        callback(null, { status: 200, data })
      } catch (error) {
        callback(error, { status: 500 })
      }
    },
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
}

i18n.use(HttpBackend).use(LanguageDetector).use(initReactI18next).init(i18nConfig)

export default i18n
