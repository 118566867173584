import { Icon, IconButton, InputAdornment } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField'
import { TextFieldProps as FormikMUITextFieldProps, fieldToTextField } from 'formik-mui'
import { FC, useCallback, useMemo, useState } from 'react'
import * as S from './VinciInput.styles'

type VinciInputProps = TextFieldProps & {
  success?: boolean
  disableshowpassword?: boolean
}

export const VinciInput: FC<VinciInputProps> = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState(false)
  const parsedType = useMemo(() => (showPassword ? 'text' : 'password'), [showPassword])

  const shouldHidePasswordToggleButton = useMemo(
    () => props.type !== 'password' || (props.type === 'password' && props.disableshowpassword),
    [props.type, props.disableshowpassword],
  )

  return (
    <S.Input
      InputProps={{
        endAdornment: !shouldHidePasswordToggleButton && (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowPassword((oldShowPassword) => !oldShowPassword)}
              edge='end'
            >
              {showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
            </IconButton>
          </InputAdornment>
        ),
        ...(props.InputProps ?? {}),
      }}
      {...props}
      type={shouldHidePasswordToggleButton ? props.type : parsedType}
    />
  )
}

export const FormikVinciInput = (props: FormikMUITextFieldProps) => {
  const {
    form: { setFieldValue },
    field: { name },
    onChange: rootOnChange,
    onBlur: rootOnBlur,
    onFocus: rootOnFocus,
    autoComplete = 'on',
  } = props

  const onChange = useCallback(
    (event: any) => {
      rootOnChange?.(event)
      setFieldValue(name, event.target?.value)
    },
    [rootOnChange, setFieldValue, name],
  )
  const onBlur = useCallback(
    (event: any) => {
      rootOnBlur?.(event)
    },
    [rootOnBlur],
  )
  const onFocus = useCallback(
    (event: any) => {
      rootOnFocus?.(event)
    },
    [rootOnFocus],
  )
  return (
    <VinciInput
      {...fieldToTextField(props)}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      autoComplete={autoComplete}
    />
  )
}
