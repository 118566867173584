import { useMutation, useQuery } from 'react-query'
import { api } from '~/data/api'
import queryClient from '~/data/queryClient'
import { getUserProfilePicture, updateUserProfilePicture } from './miscApi'

type IDashboardResponse = {
  aircraft: {
    aircraft_model: string
    count: number
  }[]
  aircraft_model: {
    aircraft_model: string
    count: number
    country: string
  }[]
  inspection_scope: [
    {
      inspection_scope: {
        [index: string]: {
          count: number
        }
      }[]
    },
  ]
  inspection_type: [
    {
      inspection_type: {
        [index: string]: {
          count: number
        }
      }[]
    },
  ]
  inspections_country_status: {
    aircraft_model: string
    count: number
    country: string
    status: string
  }[]
  status_mapa: {
    country: string
    hasDifferentStatus: boolean
  }[]
}

export const getDashboardData = async (): Promise<IDashboardResponse> => {
  const response = await api().get(`dashboard`)
  return response.data
}

export const useGetDashboardData = () => {
  return useQuery({
    queryKey: ['dashboard'],
    queryFn: getDashboardData,
    staleTime: Infinity,
  })
}

export const useGetUserProfilePicture = (userId: string) => {
  return useQuery({
    queryKey: ['user_profile_picture', userId],
    queryFn: () => getUserProfilePicture({ userId }),
    staleTime: 5 * 60 * 60 * 1000,
  })
}

export const useUpdateUserProfilePicture = () => {
  return useMutation({
    mutationFn: updateUserProfilePicture,
    onSuccess(_, props) {
      queryClient.setQueryData(['user_profile_picture', props.userId], (oldData: unknown) => {
        if (typeof oldData === 'string') {
          URL.revokeObjectURL(oldData)
        }

        return props.image ? URL.createObjectURL(props.image) : ''
      })
    },
  })
}
