import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ISubmissionValues } from '~/utils/types'

export interface ReportItem {
  reportId: string
  inspectionId: string
  submissionValues: ISubmissionValues
}

export interface ReportState {
  reports: ReportItem[]
}

const initialState: ReportState = {
  reports: [],
}

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    insertOrUpdateReport: (state: ReportState, action: PayloadAction<ReportItem>) => {
      const reportIndex = state.reports.findIndex(
        (report) => report.reportId === action.payload.reportId,
      )
      if (reportIndex === -1) state.reports.push(action.payload)
      else state.reports[reportIndex] = action.payload
    },
  },
})

export const { insertOrUpdateReport } = reportSlice.actions

export default reportSlice.reducer
