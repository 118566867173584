import { Search } from '@mui/icons-material';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VinciAccordion } from '../VinciAccordion/VinciAccordion';
import { VinciInput } from '../VinciInput';
import { VinciModal } from '../VinciModal';
import { FAQ, IQuestion, TGroups } from './FAQ';

interface IFAQModalProps {
  isOpen: boolean
  onClose: () => void
}

interface IGroupSelectionStatus {
  title: TGroups;
  selected: boolean;
}

const FAQModal = ({ isOpen, onClose }: IFAQModalProps) => {
  const { t } = useTranslation();
  const [search, handleChangeSearchTerm] = useReducer((_: unknown, { target: { value } }: ChangeEvent<HTMLInputElement>) => value, '')
  const [groupSelectionStatuses, setGroupSelectionStatuses] = useState<IGroupSelectionStatus[]>(() => {
    const groups = Object.keys(FAQ) as TGroups[]
    return groups.map(group => ({ title: group, selected: false }));
  });


  const searchQuestions = useCallback((query: string, questions: IQuestion[]): IQuestion[] => {
    const lowercaseQuery = query.toLowerCase();
    const results: IQuestion[] = [];

    for (const question of questions) {
      const lowercaseTitle = question.title.toLowerCase();
      const lowercaseAnswer = question.answer.toLowerCase();

      if (lowercaseTitle.includes(lowercaseQuery) || lowercaseAnswer.includes(lowercaseQuery)) {
        results.push(question);
      }
    }

    return results;
  }, [])

  const questions = useMemo(() => {
    const selectedGroups = groupSelectionStatuses.filter((group) => group.selected)

    if (!selectedGroups.length) {
      const questions = Object.values(FAQ).flat()

      if (!search) {
        return questions
      }

      return searchQuestions(search, questions)
    }

    const questionsFilteredByGroup = selectedGroups.map((group) => FAQ[group.title]).flat()

    if (search) {
      return searchQuestions(search, questionsFilteredByGroup)
    }

    return questionsFilteredByGroup
  }, [search, groupSelectionStatuses, searchQuestions])

  const toggleGroupSelection = useCallback((group: TGroups) => {
    setGroupSelectionStatuses(prevGroupSelectionStatuses =>
      prevGroupSelectionStatuses.map(selection =>
        selection.title === group ? { ...selection, selected: !selection.selected } : { ...selection, selected: false }
      )
    );
  }, []);


  return (
    <VinciModal open={isOpen} onClose={onClose} title={{ title: 'FAQ', subtitle: t('Answers to common questions') }}>
      <Stack gap={4} my={4}>
        <VinciInput
          label={`"${t("Search Question")}"`}
          InputProps={{ endAdornment: <Search color='inherit' /> }}
          onChange={handleChangeSearchTerm}
        />

        <Box>
          <Typography variant='overline' fontWeight="bold">{t('Filter by Type')}</Typography>
          <Stack direction="row" gap={2}>
            {groupSelectionStatuses.map(({ title, selected }) => {
              return (
                <Chip
                  variant={selected ? 'filled' : 'outlined'}
                  key={title}
                  label={t(title)}
                  color='primary'
                  onClick={() => toggleGroupSelection(title)}
                  sx={{ order: selected ? -1 : 0, fontWeight: 'medium' }}
                />
              )
            })}
          </Stack>
        </Box>

        {questions.map(({ title, answer }) =>
          <VinciAccordion
            key={t(title)}
            header={{ title }}
            styles={{
              wrapperSx: {
                boxShadow: 'none',
                borderRadius: '0 !important',
              },

              titleSx: { flex: 1 }
            }}
          >
            <Typography>
              {t(answer)}
            </Typography>
          </VinciAccordion>
        )}
      </Stack>
    </VinciModal>
  );
}

export default FAQModal;
