import {
  Box,
  Icon,
  MenuItem,
  MenuProps,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { FC, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { VinciBreadcrumbs } from '../VinciBreadcrumbs'

import { formatDistance } from 'date-fns'
import { useAuth } from '~/hooks/useAuth'
import { useIsDevice } from '~/hooks/useIsDevice'
import { useIsOnline } from '~/hooks/useIsOnline'
import { useUI } from '~/hooks/useUI'
import { UserProfilePicture } from '~/modules/misc/components/UserProfilePicture/UserProfilePicture'
import SupportSection from '../SupportSection/SupportSection'
import * as S from './Navbar.styles'

import BrFlag from '~/assets/flags/br'
import DeFlag from '~/assets/flags/de'
import EsFlag from '~/assets/flags/es'
import UsFlag from '~/assets/flags/us'

import { useTranslation } from 'react-i18next'

type ProfileMenuProps = MenuProps & {
  open?: boolean
  onClose: () => void
  anchorEl: null | HTMLElement
}

const ProfileMenu: FC<ProfileMenuProps> = (props) => {
  const navigate = useNavigate()
  const { handleLogout } = useAuth()

  const { t } = useTranslation()

  return (
    <S.ProfileMenu
      id='profile-menu'
      {...props}
      MenuListProps={{
        'aria-labelledby': 'profile-button',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <S.ProfileMenuList>
        <S.ProfileMenuItem
          onClick={() => {
            navigate('/account')
            props.onClose && props.onClose()
          }}
        >
          <S.ProfileMenuIcon>
            <Icon>person</Icon>
          </S.ProfileMenuIcon>
          <S.ProfileMenuText>{t('My Account')}</S.ProfileMenuText>
        </S.ProfileMenuItem>
        <S.ProfileMenuItem
          onClick={() => {
            handleLogout()
            props.onClose && props.onClose()
          }}
        >
          <S.ProfileMenuIcon>
            <Icon>logout</Icon>
          </S.ProfileMenuIcon>
          <S.ProfileMenuText>{t('Log out')}</S.ProfileMenuText>
        </S.ProfileMenuItem>
      </S.ProfileMenuList>
    </S.ProfileMenu>
  )
}

const ToggleSidenavButton = () => {
  const { sidenavOpen, sidenavCollapsed, toggleSidenavCollapsed, toggleSidenavOpen } = useUI()
  const { isDesktop } = useIsDevice()

  const handleToggleSidenavClick = () => {
    if (isDesktop) {
      toggleSidenavCollapsed()
      return
    }
    toggleSidenavOpen()
  }

  const { t } = useTranslation()

  return (
    <Tooltip
      title={t(
        sidenavOpen
          ? sidenavCollapsed
            ? t('Extend side menu')
            : t('Collapse side menu')
          : t('Show side menu'),
      )}
    >
      <S.ToggleUIButton onClick={handleToggleSidenavClick}>
        <Icon color='action' fontSize='medium'>
          {!sidenavOpen || !sidenavCollapsed ? 'menu_open' : 'menu'}
        </Icon>
      </S.ToggleUIButton>
    </Tooltip>
  )
}

const removeObjectIDsFromRoute = (route: string[]) => {
  const objectIdRegexp = /^[a-f\d]{24}$/i
  return route.filter((routeItem) => !routeItem.match(objectIdRegexp))
}

export const Navbar = () => {
  const [profileMenuEl, setProfileMenuEl] = useState<null | HTMLElement>(null)
  const isProfileMenuOpen = Boolean(profileMenuEl)
  const route = useLocation().pathname.split('/').slice(1)
  const parsedRoute = removeObjectIDsFromRoute(route)
  const [shouldElevate, setShouldElevate] = useState(false)
  const isOnline = useIsOnline()

  const { user, lastLogin } = useAuth()
  const { isMobile } = useIsDevice()

  const { i18n, t } = useTranslation()
  const [languageSelected, setLanguageSelected] = useState(i18n.language)

  const handleChangeLanguage = useCallback(
    (language: string) => {
      i18n.changeLanguage(language)
      setLanguageSelected(language)
    },
    [i18n],
  )

  const handleClickProfile = (event: MouseEvent<HTMLDivElement>) => {
    setProfileMenuEl(event.currentTarget)
  }

  const handleClose = () => {
    setProfileMenuEl(null)
  }

  const handleScroll = useCallback(() => {
    const position = window.pageYOffset
    if (position > 1 && !shouldElevate) {
      setShouldElevate(true)
    }
    if (position < 1 && shouldElevate) {
      setShouldElevate(false)
    }
  }, [shouldElevate])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  const theme = useTheme()

  return (
    <S.StickyWrapper shouldelevate={shouldElevate ? 'true' : null}>
      <S.Wrapper>
        <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
          <Box display='flex' alignItems='center'>
            <ToggleSidenavButton />
            <VinciBreadcrumbs title={parsedRoute[parsedRoute.length - 1]} route={parsedRoute} />
          </Box>
          {!isOnline && (
            <S.OfflineAlertWrapper>
              <S.OfflineAlertIcon />
              <S.OfflineAlertText>{t('You are offline. Trying to reconnect')}</S.OfflineAlertText>
            </S.OfflineAlertWrapper>
          )}
        </Box>

        <S.Wrapper>
          {!isMobile && <SupportSection />}
          <S.MenuButtonWrapper onClick={handleClickProfile}>
            <UserProfilePicture readOnly width={36} height={36} />
            {!isMobile && (
              <Box display='flex' flexDirection='column' gap={1} paddingY={4}>
                {user?.name && (
                  <>
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 'medium',
                      }}
                      variant='caption'
                    >
                      {t('My Profile')}
                    </Typography>{' '}
                  </>
                )}
                <Typography
                  variant='caption' color={theme.palette.text.secondary}
                  sx={{
                    width: 'max-content',
                  }}
                >
                  {t('Welcome')} {user?.name} <br /> {t('Your last log in was')}{' '}
                  {lastLogin instanceof Date &&
                    formatDistance(lastLogin, new Date(), {
                      addSuffix: true,
                      includeSeconds: true,
                    })}
                </Typography>
              </Box>
            )}
          </S.MenuButtonWrapper>
          <ProfileMenu open={isProfileMenuOpen} onClose={handleClose} anchorEl={profileMenuEl} />
          {!isMobile && (
            <S.MenuButtonWrapper>
              <Select
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                }}
                value={languageSelected}
                name='select-language'
                id='select-language'
                onChange={(e) => {
                  handleChangeLanguage(e.target.value)
                }}
              >
                <MenuItem key='en' value='en'>
                  <Icon>
                    <UsFlag />
                  </Icon>
                </MenuItem>
                <MenuItem key='br' value='br'>
                  <Icon>
                    <BrFlag />
                  </Icon>
                </MenuItem>
                <MenuItem key='es' value='es'>
                  <Icon>
                    <EsFlag />
                  </Icon>
                </MenuItem>
                <MenuItem key='de' value='de'>
                  <Icon>
                    <DeFlag />
                  </Icon>
                </MenuItem>
              </Select>
            </S.MenuButtonWrapper>
          )}
        </S.Wrapper>
      </S.Wrapper>
    </S.StickyWrapper>
  )
}