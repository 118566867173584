import { Box, Typography, styled } from '@mui/material'

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: theme.spacing(2),
}))

export const SidenavWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(2),
  maxWidth: '50px',
}))

export const ContentWrapper = styled(Box)<{
  issidebaropen?: string | null
  collapsed?: string | null
}>(({ theme, issidebaropen, collapsed }) => ({
  transition: theme.transitions.create('margin-left', {
    duration: issidebaropen
      ? theme.transitions.duration.leavingScreen
      : theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.easeInOut,
  }),
  position: 'relative',
  padding: [theme.spacing(8), theme.spacing(4)].join(' '),
  paddingTop: theme.spacing(0),
  marginLeft: issidebaropen
    ? collapsed
      ? `calc(110px + ${theme.spacing(2)})`
      : `calc(300px + ${theme.spacing(2)})`
    : 0,
}))

export const OutletWrapper = styled(Box)(() => ({
  width: '100%',
}))

export const VersionWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: '0',
  width: '100%',
  backgroundColor: theme.palette.grey[200],
  padding: '8px 0',
  zIndex: 1000,
  color: theme.palette.grey[900],
}))

export const Version = styled(Typography)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.700rem',
  color: theme.palette.common.black,
  opacity: 1,
  userSelect: 'none',
  pointerEvents: 'none',
}))

Version.defaultProps = {
  variant: 'caption',
}
