import { FC, ReactElement, useCallback, useState } from 'react'

import { Collapse, Icon } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIsDevice } from '~/hooks/useIsDevice'
import { useUI } from '~/hooks/useUI'
import * as S from './SidenavItem.styles'

type SidenavItemProps = {
  name: string
  to?: string
  type?: 'individual_item' | 'section_title' | 'collapse'
  icon?: string
  active?: boolean
  children?: ReactElement[]
  initialExpanded?: boolean
}

const SidenavIndividualItem: FC<SidenavItemProps> = ({ name, icon, to }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { sidenavCollapsed, setSidenavOpen } = useUI()
  const { isMobile } = useIsDevice()

  const isActive = location.pathname === to

  const handleClick = useCallback(() => {
    if (to) {
      navigate(to)
      isMobile && setSidenavOpen(false)
    }
  }, [navigate, to, isMobile, setSidenavOpen,])

  return (
    <S.Wrapper onClick={handleClick}>
      <S.ItemWrapper
        collapsed={sidenavCollapsed ? `$sidenavCollapsed` : null}
        active={isActive ? `${isActive}` : null}
      >
        <S.ItemIcon>
          <Icon>{icon || 'square'}</Icon>
        </S.ItemIcon>
        {!sidenavCollapsed && <S.ItemText primary={name} />}
      </S.ItemWrapper>
    </S.Wrapper>
  )
}

const SidenavSectionTitle: FC<SidenavItemProps> = ({ name }) => {
  return (
    <S.SectionTitle>
      <S.ItemWrapper>{name}</S.ItemWrapper>
    </S.SectionTitle>
  )
}

const SidenavSectionItemCollapse: FC<SidenavItemProps> = ({
  icon,
  name,
  children,
  initialExpanded = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(initialExpanded)
  const { sidenavCollapsed } = useUI()

  const handleExpand = () => {
    setIsExpanded((oldIsExpanded) => !oldIsExpanded)
  }

  return (
    <S.CollapseWrapper>
      <S.Wrapper onClick={handleExpand}>
        <S.ItemWrapper
          collapsed={sidenavCollapsed ? `$sidenavCollapsed` : null}
          active={isExpanded ? `${isExpanded}` : null}
        >
          <S.ItemIcon>
            <Icon>{icon || 'square'}</Icon>
          </S.ItemIcon>
          {!sidenavCollapsed && (
            <>
              <S.ItemText primary={name} />
              <S.CollapseIcon active={isExpanded ? `${isExpanded}` : null}>
                expand_more
              </S.CollapseIcon>
            </>
          )}
        </S.ItemWrapper>
      </S.Wrapper>
      {children && (
        <Collapse in={isExpanded} unmountOnExit>
          {children}
        </Collapse>
      )}
    </S.CollapseWrapper>
  )
}

export const SidenavItem: FC<SidenavItemProps> = ({ type = 'individual_item', ...props }) => {
  if (type === 'individual_item') return <SidenavIndividualItem {...props} />
  if (type === 'section_title') return <SidenavSectionTitle {...props} />
  if (type === 'collapse') return <SidenavSectionItemCollapse {...props} />
  return <SidenavIndividualItem {...props} />
}
