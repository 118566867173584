import { FC } from 'react'
import { useCallbackPrompt } from '~/hooks/usePrompt'
import { VinciExitDialog } from '../VinciExitLayout/VinciExitLayout'

interface IVinciPromptProps {
  when: boolean
  message?: string
}

const VinciPrompt: FC<IVinciPromptProps> = ({ when, message }) => {
  const [showPrompt, confirmNavigation, rejectNavigation] = useCallbackPrompt(when)

  return (
    <VinciExitDialog
      message={message}
      open={showPrompt}
      onConfirm={confirmNavigation}
      onReject={rejectNavigation}
    />
  )
}

export default VinciPrompt
