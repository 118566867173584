export const breakpoints = {
  values: {
    xs: 0,
    sm: 480,
    md: 640,
    lg: 960,
    xl: 1280,
    laptop: 1367,
  },
}
