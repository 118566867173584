import { Icon } from '@mui/material'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { breadcumbMapper } from '~/utils/breadcumbMapper'
import * as S from './VinciBreadcrumbs.styles'

type VinciBreadcrumbsProps = { icon?: string; title: string; route: string[] }

const parseRoute = (route: string) => {
  const hasMapped = Object.hasOwn(breadcumbMapper, route)
  if (hasMapped) return breadcumbMapper[route]
  return route.replace('-', ' ').replace('_', ' ')
}

const parseRouteLink = (route: string) => (route.replace(' ', '_').toLowerCase())

export const VinciBreadcrumbs: FC<VinciBreadcrumbsProps> = ({ icon = 'home', title, route }) => {
  const routes = route.slice(0, -1)
  const parsedRoutes = routes.map(parseRoute)
  const parsedTitle = parseRoute(title)

  const { t } = useTranslation()
  return (
    <S.Wrapper>
      <S.Breadcumbs>
        <Link to={'/'}>
          <S.IconWrapper>
            <Icon>{icon}</Icon>
          </S.IconWrapper>
        </Link>

        {parsedRoutes?.map((el: string) => (
          <Link to={`/${parseRouteLink(el)}`} key={el}>
            <S.BreadcumbItem>{t(el)}</S.BreadcumbItem>
          </Link>
        ))}
        <S.BreadcumbItem active='true'>{t(parsedTitle)}</S.BreadcumbItem>
      </S.Breadcumbs>
      <S.Title>{t(parsedTitle)}</S.Title>
    </S.Wrapper>
  )
}
