import { ConfirmOptions } from 'material-ui-confirm'
import { containedStyles, outlinedStyles } from './button/'

export * from './button'

export const confirmProviderOptions:{ key:string, options:ConfirmOptions}[] = [
  {
    key:'en',
    options:{
      title: 'Confirmation',
      confirmationText: 'Yes',
      cancellationText: 'No',
      confirmationButtonProps: {
        variant: 'contained',
        sx: containedStyles,
      },
      cancellationButtonProps: {
        variant: 'outlined',
        sx: outlinedStyles,
      },
    }
  },{
    key:'br',
    options:{
      title: 'Confirmação',
      confirmationText: 'Sim',
      cancellationText: 'Não',
      confirmationButtonProps: {
        variant: 'contained',
        sx: containedStyles,
      },
      cancellationButtonProps: {
        variant: 'outlined',
        sx: outlinedStyles,
      },
    }
  },{
    key:'es',
    options:{
      title: 'Confirmación',
      confirmationText: 'Sí',
      cancellationText: 'No',
      confirmationButtonProps: {
        variant: 'contained',
        sx: containedStyles,
      },
      cancellationButtonProps: {
        variant: 'outlined',
        sx: outlinedStyles,
      },
    }
  },
  {
    key:'de',
    options:{
      title: 'Bestätigung',
      confirmationText: 'Ja',
      cancellationText: 'Nein',
      confirmationButtonProps: {
        variant: 'contained',
        sx: containedStyles,
      },
      cancellationButtonProps: {
        variant: 'outlined',
        sx: outlinedStyles,
      },
    }
  }
]

