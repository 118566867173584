import { Dialog, DialogActions, DialogContent, DialogTitle, styled } from '@mui/material'

export const Wrapper = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    maxWidth: /* theme.breakpoints.values['sm'] */ '90vw',
    width: 'fit-content',
    borderRadius: '.8rem',
  },
}))

export const Title = styled(DialogTitle)(() => ({}))

export const ActionsWrapper = styled(DialogActions)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(2),
  padding: theme.spacing(4),
  display: 'flex',
  justifyContent: 'end',
}))

export const Content = styled(DialogContent)(({ theme }) => ({
  maxHeight: '60vh',
  padding: theme.spacing(2),
}))
