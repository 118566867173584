import { Box, styled } from '@mui/material'

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  borderRadius: '1rem',
  '& .MuiDataGrid-root': {
    border: 'none',
    borderRadius: '1rem',
    padding: theme.spacing(2),
  },
  '& .MuiDataGrid-toolbarContainer': {
    display: 'flex',
    gap: theme.spacing(2),
  },
}))

export const DataGridWrapper = styled(Box)(() => ({
  flexGrow: 1,
  maxHeight: '100%',
  overflowY: 'auto',
}))
