import { palette } from '../base/palette'

export const inputOutlinedStyles = {
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      fontSize: '0.875rem',
      borderRadius: '0.375rem',

      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#d2d6da',
      },

      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.info.main,
        },
      },
    },

    notchedOutline: {
      borderColor: '#d2d6da',
    },

    input: {
      color: palette.grey[700],
      padding: '0.75rem',
      backgroundColor: 'transparent',
    },

    inputSizeSmall: {
      fontSize: '0.75rem',
      padding: '0.625rem',
    },

    multiline: {
      color: palette.grey[700],
      padding: 0,
    },
  },
}
