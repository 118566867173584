import axios, { AxiosInstance } from 'axios'
import {
  API_URL,
  FRESHDESK_API_PASSWORD,
  FRESHDESK_API_URL,
  FRESHDESK_API_USER,
  STATE_STORAGE_LOCATION,
} from '~/utils/consts'

export const api = () => {
  const defaultOptions = {
    baseURL: API_URL,
    validateStatus: (statusNumber: number) => statusNumber >= 200 && statusNumber < 300,
  }

  const instance = axios.create(defaultOptions)
  instance.defaults.headers.common['Content-Type'] = 'application/json'

  addTokenToHeaders(instance)

  return instance
}

const addTokenToHeaders = (instance: AxiosInstance) => {
  if (!sessionStorage.getItem(STATE_STORAGE_LOCATION)) return
  const token = JSON.parse(sessionStorage.getItem(STATE_STORAGE_LOCATION) || '').auth?.token
  instance.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : ''
}

export const freshdesk = axios.create({
  baseURL: FRESHDESK_API_URL,
  auth: {
    username: FRESHDESK_API_USER,
    password: FRESHDESK_API_PASSWORD,
  },
})
