import { Support } from '@mui/icons-material'
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, styled } from '@mui/material'

export const SupportMenuButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  transition: theme.transitions.create('background', {
    easing: 'ease-in-out',
    duration: '200ms',
  }),

  '&:hover': { background: theme.palette.grey[300] },
}))

export const SupportMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: '1px solid rgba(0,0,0,.05)',
    borderRadius: '.5rem',
    boxShadow:
      'rgb(255 255 255 / 90%) 0rem 0rem 0.0625rem 0.0625rem inset, rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem',
  },
  '& .MuiPaper-root > .MuiList-root': {
    padding: theme.spacing(2),
  },
}))
export const SupportMenuList = styled(MenuList)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))
export const SupportMenuItem = styled(MenuItem)(() => ({
  borderRadius: '.5rem',
}))

export const SupportSectionIcon = styled(Support)(() => ({
  fontSize: '2rem !important',
}))
export const SupportMenuIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.grey[900],
}))
export const SupportMenuText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.grey[900],
}))
