import { Box, LinearProgress, useTheme } from '@mui/material'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Layout } from '~/components/Layout'
import { ManagerViewProvider } from './contexts/manager-view.context'

const ProtectedRoute = React.lazy(() =>
  import('~/components/ProtectedRoute').then((module) => ({ default: module.ProtectedRoute })),
)

const AircraftManufacturerTableScreen = React.lazy(() =>
  import('~aircraftManufacturer/pages/AircraftManufacturerTableScreen').then((module) => ({
    default: module.AircraftManufacturerTableScreen,
  })),
)
const ForgotPasswordScreen = React.lazy(() =>
  import('~auth/pages/ForgotPassword').then((module) => ({ default: module.ForgotPasswordScreen })),
)
const LoginScreen = React.lazy(() =>
  import('~auth/pages/SignIn').then((module) => ({ default: module.LoginScreen })),
)
const CompanyTableScreen = React.lazy(() =>
  import('~company/pages/CompanyTableScreen').then((module) => ({
    default: module.CompanyTableScreen,
  })),
)
const InspectionTypeTableScreen = React.lazy(() =>
  import('~inspectionType/pages/InspectionTypeTableScreen').then((module) => ({
    default: module.InspectionTypeTableScreen,
  })),
)
const AccountScreen = React.lazy(() =>
  import('~misc/pages/AccountScreen').then((module) => ({ default: module.AccountScreen })),
)
const DashboardScreen = React.lazy(() =>
  import('~misc/pages/DashboardScreen').then((module) => ({ default: module.DashboardScreen })),
)
const UserScreen = React.lazy(() =>
  import('~user/pages').then((module) => ({ default: module.UserScreen })),
)
const FillReport = React.lazy(() =>
  import('././modules/inspection/pages/FillReport/FillReport').then((module) => ({
    default: module.FillReport,
  })),
)
const AircraftModelTableScreen = React.lazy(() =>
  import('./modules/aircraft_model/pages/AircraftModelTableScreen').then((module) => ({
    default: module.AircraftModelTableScreen,
  })),
)
const ResetPasswordScreen = React.lazy(() =>
  import('./modules/auth/pages/ResetPassword/ResetPassword').then((module) => ({
    default: module.ResetPasswordScreen,
  })),
)
const UpdatePasswordScreen = React.lazy(() =>
  import('./modules/auth/pages/UpdatePassword/UpdatePassword').then((module) => ({
    default: module.UpdatePasswordScreen,
  })),
)
const OTPScreen = React.lazy(() =>
  import('./modules/auth/pages/OTP/OTP').then((module) => ({ default: module.OTPScreen })),
)
const CompanyScreen = React.lazy(() =>
  import('./modules/company/pages').then((module) => ({ default: module.CompanyScreen })),
)
const ComponentTableScreen = React.lazy(() =>
  import('./modules/component/pages/ComponentTableScreen').then((module) => ({
    default: module.ComponentTableScreen,
  })),
)
const ComponentParameterTableScreen = React.lazy(() =>
  import('./modules/component_parameter/pages/ComponentParameterTableScreen').then((module) => ({
    default: module.ComponentParameterTableScreen,
  })),
)
const ImageRecognitionScreen = React.lazy(() =>
  import('./modules/image_recognition/pages/ImageRecognitionScreen').then((module) => ({
    default: module.ImageRecognitionScreen,
  })),
)
const CreateInspection = React.lazy(() =>
  import('./modules/inspection/pages/create-inspection').then((module) => ({
    default: module.CreateInspection,
  })),
)
const EditInspection = React.lazy(() =>
  import('./modules/inspection/pages/edit-inspection').then((module) => ({
    default: module.EditInspection,
  })),
)
const ListInspectionScreen = React.lazy(() =>
  import('./modules/inspection/pages/list-inspection/ListInspection').then((module) => ({
    default: module.ListInspectionScreen,
  })),
)
const ShowInspection = React.lazy(() =>
  import('./modules/inspection/pages/show-inspection').then((module) => ({
    default: module.ShowInspection,
  })),
)
const ListAsset = React.lazy(() =>
  import('./modules/aircraft/pages/list-asset').then((module) => ({ default: module.ListAsset })),
)

const ListAssetManager = React.lazy(() =>
  import('./modules/manager_view/pages').then((module) => ({
    default: module.ListAssetManagers,
  })),
)

const InspectionChecklistScreen = React.lazy(() =>
  import(
    './modules/inspection_checklist/pages/InspectionChecklistScreen/InspectionChecklistScreen'
  ).then((module) => ({ default: module.InspectionChecklistScreen })),
)
const InspectionChecklistTableScreen = React.lazy(() =>
  import('./modules/inspection_checklist/pages/InspectionChecklistTableScreen').then((module) => ({
    default: module.InspectionChecklistTableScreen,
  })),
)
const InspectionItemTableScreen = React.lazy(() =>
  import('./modules/inspection_item/pages/InspectionItemTableScreen').then((module) => ({
    default: module.InspectionItemTableScreen,
  })),
)
const InspectionScopeTableScreen = React.lazy(() =>
  import('./modules/inspection_scope/pages/InspectionScopeTableScreen').then((module) => ({
    default: module.InspectionScopeTableScreen,
  })),
)
const NotFoundScreen = React.lazy(() =>
  import('./modules/misc/pages/NotFoundScreen').then((module) => ({
    default: module.NotFoundScreen,
  })),
)
const PhysicalItemTableScreen = React.lazy(() =>
  import('./modules/physical_item/pages/PhysicalItemTableScreen').then((module) => ({
    default: module.PhysicalItemTableScreen,
  })),
)
const ProfileScreen = React.lazy(() =>
  import('./modules/profile/pages').then((module) => ({ default: module.ProfileScreen })),
)
const ReportTemplateTableScreen = React.lazy(() =>
  import(
    './modules/report_template/pages/ReportTemplateTableScreen/ReportTemplateTableScreen'
  ).then((module) => ({ default: module.ReportTemplateTableScreen })),
)
const FileClassTableScreen = React.lazy(() =>
  import('./modules/file_class/pages/FileClassTableScreen/FileClassTableScreen').then((module) => ({
    default: module.FileClassTableScreen,
  })),
)
const CreateFileClassScreen = React.lazy(() =>
  import('./modules/file_class/pages/CreateFileClassScreen/CreateFileClassScreen').then(
    (module) => ({ default: module.CreateFileClassScreen }),
  ),
)
const ManagerAssetDetailScreen = React.lazy(() =>
  import('./modules/manager_view/pages/ManagerAssetDetailScreen').then((module) => ({
    default: module.ManagerAssetDetailScreen,
  })),
)

const DefaultLoader = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: '100%',
        marginTop: theme.spacing(2),
      }}
    >
      <LinearProgress aria-busy='true' sx={{ width: '20rem', margin: '0 auto' }} />
    </Box>
  )
}

export const publicRoutes = [
  '/auth/pin',
  '/auth/forgot-password',
  '/auth/reset-password',
  '/privacy',
  '/terms',
]

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path='/auth/login'
        element={
          <React.Suspense fallback={<DefaultLoader />}>
            <LoginScreen />
          </React.Suspense>
        }
      />
      <Route
        path='/auth/pin'
        element={
          <React.Suspense fallback={<DefaultLoader />}>
            <OTPScreen />
          </React.Suspense>
        }
      />
      <Route
        path='/auth/forgot-password'
        element={
          <React.Suspense fallback={<DefaultLoader />}>
            <ForgotPasswordScreen />
          </React.Suspense>
        }
      />
     <Route
        path='/auth/reset-password'
        element={
          <React.Suspense fallback={<DefaultLoader />}>
            <ResetPasswordScreen />
          </React.Suspense>
        }
      />

      <Route
        path='/update-password'
        element={
          <React.Suspense fallback={<DefaultLoader />}>
            <UpdatePasswordScreen />
          </React.Suspense>
        }
      />

      <Route
        element={
          <React.Suspense fallback={<DefaultLoader />}>
            <Layout />
          </React.Suspense>
        }
      >
        <Route
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute />
            </React.Suspense>
          }
        >
          <Route
            path='/dashboard'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <DashboardScreen />
              </React.Suspense>
            }
          />
          <Route
            path='assets'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <ProtectedRoute requiredPermission='list_asset_as_manager' />
              </React.Suspense>
            }
          >
            <Route
              index
              element={
                <React.Suspense fallback={<DefaultLoader />}>
                  <ListAssetManager />
                </React.Suspense>
              }
            />
          </Route>
          <Route
            path='/assets/:id'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <ProtectedRoute requiredPermission='detail_asset_as_manager' />
              </React.Suspense>
            }
          >
            <Route
              index
              element={
                <React.Suspense fallback={<DefaultLoader />}>
                  <ManagerViewProvider>
                    <ManagerAssetDetailScreen />
                  </ManagerViewProvider>
                </React.Suspense>
              }
            />
          </Route>
          <Route
            path='/profiles'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <ProfileScreen />
              </React.Suspense>
            }
          />
          <Route
            path='/image_recognition'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <ImageRecognitionScreen />
              </React.Suspense>
            }
          />
          <Route path='/account'>
            <Route
              index
              element={
                <React.Suspense fallback={<DefaultLoader />}>
                  <AccountScreen />
                </React.Suspense>
              }
            />
            <Route
              path=':id'
              element={
                <React.Suspense fallback={<DefaultLoader />}>
                  <AccountScreen />
                </React.Suspense>
              }
            />
          </Route>
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <ListInspectionScreen />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='audit'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_inspection' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <ListInspectionScreen />
              </React.Suspense>
            }
          />
          <Route
            path=':id'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <ShowInspection />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='audit'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='create_inspection' />
            </React.Suspense>
          }
        >
          <Route
            path='create'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <CreateInspection />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='audit'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='edit_inspection' />
            </React.Suspense>
          }
        >
          <Route
            path='fill_report/:id'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <FillReport />
              </React.Suspense>
            }
          />
          <Route
            path='edit/:id'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <EditInspection />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='users'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_user' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <UserScreen />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='companies'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_company' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <CompanyTableScreen />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path='companies'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='create_company' />
            </React.Suspense>
          }
        >
          <Route
            path='create'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <CompanyScreen />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path='companies'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='edit_company' />
            </React.Suspense>
          }
        >
          <Route
            path='edit'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <CompanyScreen editing />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='assets_config'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_asset' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <ListAsset />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path='asset_manufacturers'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_asset_manufacturer' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <AircraftManufacturerTableScreen />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='asset_models'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_asset_model' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <AircraftModelTableScreen />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='inspection_items'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_inspection_item' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <InspectionItemTableScreen />
              </React.Suspense>
            }
          />
        </Route>

        {/* <Route
          path='valuation_items'
          element={<React.Suspense fallback={<DefaultLoader />}><ProtectedRoute requiredPermission='list_valuation_item' /></React.Suspense>}
        >
          <Route index element={<React.Suspense fallback={<DefaultLoader />}><ValuationItemTableScreen /></React.Suspense>} />
        </Route> */}

        <Route
          path='inspecion_types'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_inspection_type' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <InspectionTypeTableScreen />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='inspection_scopes'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_inspection_scope' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <InspectionScopeTableScreen />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='physical_items'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_physical_item' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <PhysicalItemTableScreen />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='components'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_component' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <ComponentTableScreen />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='component_parameters'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_component_parameter' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <ComponentParameterTableScreen />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='report_templates'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_report_template' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <ReportTemplateTableScreen />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='inspection_checklists'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_inspection_checklist' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <InspectionChecklistTableScreen />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='inspection_checklists'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='create_inspection_checklist' />
            </React.Suspense>
          }
        >
          <Route
            path='create'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <InspectionChecklistScreen />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path='inspection_checklists'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='edit_inspection_checklist' />
            </React.Suspense>
          }
        >
          <Route
            path='edit/:id'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <InspectionChecklistScreen editing />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='file_classes'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='list_file_class' />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <FileClassTableScreen />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='file_classes'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='create_file_class' />
            </React.Suspense>
          }
        >
          <Route
            path='create'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <CreateFileClassScreen />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path='file_classes'
          element={
            <React.Suspense fallback={<DefaultLoader />}>
              <ProtectedRoute requiredPermission='edit_file_class' />
            </React.Suspense>
          }
        >
          <Route
            path='edit/:id'
            element={
              <React.Suspense fallback={<DefaultLoader />}>
                <CreateFileClassScreen editing />
              </React.Suspense>
            }
          />
        </Route>
      </Route>

      <Route
        path='*'
        element={
          <React.Suspense fallback={<DefaultLoader />}>
            <NotFoundScreen />
          </React.Suspense>
        }
      />
    </Routes>
  )
}
