import React from 'react'
import { useAuth } from '~/hooks/useAuth'

type PermissionList = {
  operation: 'and' | 'or'
  permissions: string[]
}

interface ProtectedComponentProps {
  requiredPermission: string | PermissionList
}

export const ProtectedComponent: React.FC<React.PropsWithChildren<ProtectedComponentProps>> = ({
  requiredPermission,
  children,
}) => {
  const { isAssigned } = useAuth()

  if (typeof requiredPermission === 'object') {
    if (requiredPermission.operation === 'and')
      return requiredPermission.permissions.every((permission) => isAssigned(permission)) ? (
        <>{children}</>
      ) : null

    if (requiredPermission.operation === 'or')
      return requiredPermission.permissions.some((permission) => isAssigned(permission)) ? (
        <>{children}</>
      ) : null
  }

  if (isAssigned(requiredPermission as string)) {
    return <>{children}</>
  }

  return null
}
