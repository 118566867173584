import { ThemeProvider, createTheme } from '@mui/material'
import ReactGA from 'react-ga4'
import { Provider } from 'react-redux'
import { ToastContainer, Zoom } from 'react-toastify'
import { AppRoutes } from './AppRoutes'
import { store } from './store'
import { theme } from './styles/theme'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ConfirmProvider } from 'material-ui-confirm'
import { confirmProviderOptions } from './styles/confirm/'

import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import queryClient from './data/queryClient'

import { useTranslation } from 'react-i18next'
import 'react-toastify/dist/ReactToastify.css'
import { IdleDetector } from './components'

import { deDE, enUS, esES, ptBR } from '@mui/material/locale'
import {
  ptBR as gridBR,
  deDE as gridDE,
  esES as gridES,
  enUS as gridUS,
} from '@mui/x-data-grid/locales'
import { setDefaultOptions } from 'date-fns'
import {
  de as deDateFns,
  enUS as enUSDateFns,
  es as esDateFns,
  ptBR as ptBRDateFns,
} from 'date-fns/locale'
import { useEffect, useMemo } from 'react'

function App() {
  const Translations = useMemo(
    () => [
      {
        key: 'en',
        grid: gridUS,
        mui: enUS,
        dateFns: enUSDateFns,
      },
      {
        key: 'br',
        grid: gridBR,
        mui: ptBR,
        dateFns: ptBRDateFns,
      },
      {
        key: 'es',
        grid: gridES,
        mui: esES,
        dateFns: esDateFns,
      },
      {
        key: 'de',
        grid: gridDE,
        mui: deDE,
        dateFns: deDateFns,
      },
    ],
    [],
  )
  const { i18n } = useTranslation()

  const translateTheme = createTheme(
    { ...theme },
    Translations.find((t) => t.key === i18n.language)?.mui ?? enUS,
    Translations.find((t) => t.key === i18n.language)?.grid ?? gridUS,
  )

  ReactGA.initialize('G-DL08W7DJY1')

  useEffect(() => {
    setDefaultOptions({
      locale: Translations.find((t) => t.key === i18n.language)?.dateFns ?? enUSDateFns,
    })
  }, [i18n.language, Translations])

  return (
    <Provider store={store}>
      <ThemeProvider theme={translateTheme}>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ConfirmProvider
              defaultOptions={
                confirmProviderOptions.find((item) => item?.key === i18n.language)?.options
              }
            >
              <AppRoutes />
              <ToastContainer
                pauseOnHover
                closeOnClick
                autoClose={3000}
                position='top-right'
                hideProgressBar={false}
                transition={Zoom}
              />
              <IdleDetector />
            </ConfirmProvider>
          </LocalizationProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
