import {
  Box,
  Accordion as MUIAccordion,
  AccordionDetails as MUIAccordionDetails,
  AccordionSummary as MUIAccordionSummary,
  Typography,
  styled,
} from '@mui/material'

export const Wrapper = styled(Box)(() => ({
  width: '100%',
}))

export const AccordionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  borderRadius: '0.8rem',
  marginBottom: theme.spacing(4),
}))

export const Accordion = styled(MUIAccordion)<{
  hide?: boolean
  disablePadding?: boolean
}>(({ hide, theme, disablePadding }) => ({
  display: hide ? 'none' : 'block',
  width: '100%',
  padding: disablePadding ? '' : [theme.spacing(1), theme.spacing(2)].join(' '),
  minWidth: '100%',
  borderRadius: theme.shape.borderRadius,

  '&:before': {
    backgroundColor: 'transparent',
  },
  '&:not(:only-child)': {
    borderTop: '1px solid #ccc',
    '&:first-of-type': {
      borderTop: '1px solid transparent',
    },
  },
}))

export const AccordionSummary = styled(MUIAccordionSummary)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
}))
export const AccordionTitle = styled(Typography)(() => ({
  margin: 'auto 0',
  color: 'inherit',
  width: '33%',
  fontSize: '1.2rem',
}))
AccordionTitle.defaultProps = {
  variant: 'h5',
}

export const AccordionActions = styled(Box)(({ theme }) => ({
  marginRight: 0,
  marginLeft: 'auto',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(1),
  paddingRight: theme.spacing(2),
  fontSize: '1.2rem',
}))
export const AccordionDetails = styled(MUIAccordionDetails)(() => ({
  width: '100%',
}))

export const AccordionDescription = styled(Typography)<{ highlight?: string }>(
  ({ theme, highlight }) => ({
    color: highlight === 'true' ? theme.palette.primary.main : theme.palette.text.primary,
    margin: 'auto 0',
  }),
)

export const LeftSideWrapper = styled(Box)(() => ({
  flex: 1,
}))

export const RightSideWrapper = styled(Box)(() => ({
  flex: 1,
}))
