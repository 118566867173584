export const isDevelopment = import.meta.env.DEV || ENVIRONMENT !== 'prod'
export const isPreview = Boolean(isDevelopment || !['prod', 'development'].includes(ENVIRONMENT))

export const getStrictAppVersion = (): string => {
  if (!isDevelopment) return APP_VERSION

  const parsedEnviroment = ENVIRONMENT || 'develop'

  return `${APP_VERSION}-${parsedEnviroment}`
}

export const getAppVersion = (): string => getStrictAppVersion()
