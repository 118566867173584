import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { reportSlice } from '~/modules/inspection/store/'
import {
  loadState,
  loadStateFromSessionStorage,
  saveState,
  saveStateToSessionStorage,
} from '~/utils/storeManager'
import authSlice from './slices/authSlice'
import uiSlice from './slices/uiSlice'

const reducers = combineReducers({
  auth: authSlice,
  ui: uiSlice,
  report: reportSlice,
})

export const store = configureStore({
  reducer: reducers,
  preloadedState: {
    auth: loadStateFromSessionStorage && loadStateFromSessionStorage()?.auth,
    ui: loadState && loadState()?.ui,
    report: loadState && loadState()?.report,
  },
})

store.subscribe(() => {
  saveStateToSessionStorage({
    auth: store.getState().auth,
  })
  saveState({
    ui: store.getState().ui,
    report: store.getState().report,
  })
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
