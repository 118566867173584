import React, { useCallback } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { toast } from 'react-toastify'
import { useAuth } from '~/hooks/useAuth'
import { VinciExitDialog } from '../VinciExitLayout/VinciExitLayout'

const timeout = 60 * 60 * 1000 // 30 minutes
const promptBeforeIdle = 15 * 60 * 1000 // 15 minutes

export const IdleDetector = () => {
  const { handleLogout, isLogged } = useAuth()
  const [promptModalOpen, changePromptModalOpen] = React.useReducer(
    (_: unknown, state: boolean) => state,
    false,
  )
  const [remaining, setRemaining] = React.useState<number>(timeout)

  const onIdle = () => {
    changePromptModalOpen(false)
    handleLogout()
    toast.error('You has been logged out for innactivity. Please sign in again.', {
      toastId: 'logout-toast',
    })
  }
  const onActive = () => {
    changePromptModalOpen(false)
  }
  const onPrompt = () => {
    if (!isLogged) return
    changePromptModalOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    promptBeforeIdle,
    timeout,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  })

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    activate()
  }

  const formatTime = useCallback((time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time) - (minutes * 60)

    if (minutes > 0) {
      return `${minutes} minutes and ${seconds} seconds`
    } else {
      return `${seconds} seconds`
    }
  }, [])

  return (
    <VinciExitDialog
      open={promptModalOpen}
      message={`Are you still there? Logging out in ${formatTime(remaining)}.`}
      onConfirm={handleStillHere}
      onClose={handleStillHere}
    />
  )
}
