import { Delete, Upload } from '@mui/icons-material'
import {
  Box,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  SxProps,
  useTheme,
} from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { VinciPlaceholderImage } from '~/components/VinciPlaceholderImage/VinciPlaceholderImage'
import VinciUploadArea, { TVinciUploadAreaRef } from '~/components/VinciUploadArea/VinciUploadArea'
import { useAuth } from '~/hooks/useAuth'
import useRequestWithToast from '~/hooks/useRequestWithToast'
import { IUpdateUserProfilePicture } from '../../data/miscApi'
import { useGetUserProfilePicture, useUpdateUserProfilePicture } from '../../data/useData'

interface IUserProfilePictureProps {
  userId?: string
  readOnly?: boolean
  width?: number
  height?: number
  wrapperSx?: SxProps
}

const allowedMimes = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/x-png': ['.png'],
  'image/png': ['.png'],
}

export const UserProfilePicture: FC<IUserProfilePictureProps> = ({
  userId: remoteUserId,
  readOnly = true,
  width = 150,
  height = 150,
  wrapperSx,
}) => {
  const { userId: loggedInUserId } = useAuth()

  const userId = useMemo(() => remoteUserId ?? loggedInUserId, [remoteUserId, loggedInUserId])

  const { request } = useRequestWithToast()
  const confirm = useConfirm()

  const { data: profileUrl, isLoading } = useGetUserProfilePicture(userId || '')

  const uploadRef = useRef<TVinciUploadAreaRef>(null)

  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null)

  const { t } = useTranslation()

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault()

      if (readOnly) return
      setAnchor(event.currentTarget)
    },
    [readOnly],
  )

  const handleCloseMenu = useCallback(() => {
    setAnchor(null)
  }, [])

  const { mutateAsync: updateUserProfilePicture } = useUpdateUserProfilePicture()

  const handleChangePicture = useCallback(
    async (image: File) => {
      if (!userId) return

      await request<IUpdateUserProfilePicture>({
        requestFunction: updateUserProfilePicture,
        requestProps: {
          image,
          userId,
        },
        initialText: t('Updating profile picture...'),
        errorText: t('There was an error updating picture, please try again!'),
        successText: t('Profile picture successfully updated.'),
      })
    },
    [userId, request, updateUserProfilePicture, t],
  )

  const handleRemovePicture = useCallback(async () => {
    if (!userId) return

    await request<IUpdateUserProfilePicture>({
      requestFunction: updateUserProfilePicture,
      requestProps: {
        image: null,
        userId,
      },
      initialText: t('Removing profile picture...'),
      errorText: t('There was an error removing picture, please try again!'),
      successText: t('Profile picture successfully removed.'),
    })
  }, [request, userId, updateUserProfilePicture, t])

  const theme = useTheme()
  return (
    <>
      <Box
        sx={
          wrapperSx ?? {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }
        }
      >
        <Box
          onClick={handleOpenMenu}
          sx={{
            position: 'relative',
            borderRadius: '50%',
            overflow: 'hidden',
            height,
            width,
            cursor: 'pointer',

            ...(!readOnly
              ? {
                  '&:hover': {
                    '> div': {
                      backgroundColor: 'rgba(0, 0, 0, .4)',
                      opacity: 1,
                    },
                  },
                }
              : {}),
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              opacity: 0,
              backgroundColor: 'rgba(0, 0, 0, 0)',
              zIndex: 999,
              transition: theme.transitions.create('all', {
                duration: `.2s`,
              }),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon sx={{ zIndex: 9999, color: '#fff' }}>edit</Icon>
          </Box>
          <VinciUploadArea
            ref={uploadRef}
            allowedMimes={allowedMimes}
            maxFiles={1}
            onAcceptFiles={(files: File[]) => {
              if (!files.length) return

              handleChangePicture(files[0])
            }}
          >
            {!isLoading && profileUrl && (
              <VinciPlaceholderImage
                url={profileUrl || ''}
                imageSx={{
                  borderRadius: '50%',
                  height,
                  width,
                  objectFit: 'cover',
                }}
                placeholderElement={
                  <Skeleton variant='circular' height={150} width={150} animation='wave' />
                }
              />
            )}
            {!isLoading && !profileUrl && (
              <Icon
                sx={{
                  fontSize: width,
                  color: theme.palette.grey[700],
                }}
              >
                account_circle
              </Icon>
            )}
          </VinciUploadArea>
        </Box>
        <Menu
          id='profile-picture-menu'
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={handleCloseMenu}
          elevation={1}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            onClick={() => {
              handleCloseMenu()
              uploadRef.current?.openDialog()
            }}
            disableRipple
          >
            <ListItemIcon>
              <Upload />
            </ListItemIcon>
            <ListItemText>{t('Upload new')}</ListItemText>
          </MenuItem>
          {!isLoading && profileUrl && (
            <MenuItem
              onClick={() => {
                handleCloseMenu()
                confirm({
                  description: t('Are you sure you want to remove profile picture?'),
                }).then(handleRemovePicture)
              }}
              disableRipple
            >
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText>{t('Remove')}</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </Box>
    </>
  )
}

