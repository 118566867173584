import { Id, toast } from 'react-toastify'

export const updateToastInfo = (toastId: Id, text: string, type: 'error' | 'success') => {
  const commonToastProps = {
    autoClose: 3000,
    pauseOnHover: true,
    closeOnClick: true,
    hideProgressBar: false,
    isLoading: false,
  }
  toast.update(toastId, { ...commonToastProps, type, render: text })
}
