import { FormikValues } from 'formik'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { freshdesk } from '~/data/api'
import { useAuth } from '~/hooks/useAuth'
import { initialSupport } from '~/utils/types'
import { TSupportType } from '../SupportSection/SupportSection'
import { VinciFormModal } from '../VinciFormModal'
import { SupportForm } from './SupportForm'

interface IDeadlineModalProps {
  onClose: () => void
  type: TSupportType
}

export const preFilledValidationSchema = yup.object({
  subject: yup.string().required('Subject is Required'),
  description: yup.string().required('Description is required'),
})

export const userEnteredValidationSchema = yup.object({
  name: yup.string().required('Enter your name'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  phone_number: yup.string().required('Phone number is required'),
  subject: yup.string().required('Subject is Required'),
  description: yup.string().required('Description is required'),
})

export const supportLabels = {
  request_feature: {
    title: 'Suggest a new feature',
    subtitle: 'Suggest a new addition.',
    type: 'Feature Request',
    priority: '1',
  },
  report_bug: {
    title: 'Report a bug',
    subtitle: 'Let us know about issues.',
    type: 'Problem',
    priority: '3',
  },
}

export const SupportModal = ({ type, onClose }: IDeadlineModalProps) => {
  const { user } = useAuth()
  const validationSchema = !user ? userEnteredValidationSchema : preFilledValidationSchema

  const labels = supportLabels[type]
  const [attachments, setAttachments] = useState<File[]>([])

  const { t } = useTranslation()

  const handleAddAttachments = useCallback(
    (newAttachments: File[]) => {
      const conflicts = newAttachments.some((newAttachment) =>
        attachments.some((existingAttachment) => existingAttachment.name === newAttachment.name),
      )

      if (conflicts) {
        toast.error(
          t(
            'Some attachments have conflicting names. Please ensure each attachment has a unique name',
          ),
        )
        return
      }
      setAttachments((prevAttachments) => [...prevAttachments, ...newAttachments])
    },
    [attachments, t],
  )

  const handleRemoveAttachments = useCallback((name: string) => {
    setAttachments((attachments) => attachments.filter((attachment) => attachment.name !== name))
  }, [])

  const handleSubmit = async (values: FormikValues) => {
    const { email, name, phone_number } = user || values
    const { subject, description } = values

    const status = '2'
    const source = '2'

    const formData = new FormData()

    formData.append('subject', subject)
    formData.append('description', description)
    formData.append('email', email)
    formData.append('name', name)
    formData.append('phone', phone_number)
    formData.append('status', status)
    formData.append('priority', labels.priority)
    formData.append('source', source)
    formData.append('type', labels.type)

    attachments.forEach((file) => {
      formData.append('attachments[]', file)
    })

    try {
      await freshdesk.post('/tickets', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      toast.success(
        t(
          'Ticket created successfully! Our support team will reach out to you shortly. Please feel free to reach out to our support team at zeh@eyesonasset.com',
        ),
      )
      onClose()
    } catch (error) {
      toast.error(
        t(
          'Failed to create the ticket. Please try again later or contact support (zeh@eyesonasset.com) for assistance."',
        ),
      )
    }
  }

  return (
    <VinciFormModal
      form={{
        initialValues: initialSupport(user),
        onSubmit: handleSubmit,
        validationSchema: validationSchema,
      }}
      opened={Boolean(type)}
      onClose={onClose}
      title={labels}
      confirmButtonLabel={t('Send')}
      alwaysPromptOnClose
    >
      <SupportForm
        attachments={attachments}
        handleAddAttachments={handleAddAttachments}
        handleRemoveAttachments={handleRemoveAttachments}
        user={Boolean(user)}
      />
    </VinciFormModal>
  )
}
