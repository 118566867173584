import { Backdrop, CircularProgress, LinearProgress } from '@mui/material'
import { FC, ReactElement, useMemo } from 'react'

export type TBackdropLoaderType = 'circular' | 'linear'

interface IBackdropLoaderProps {
  onClose?: () => void
  customLoader?: ReactElement
  loaderType?: TBackdropLoaderType
  progress?: number
}

export const BackdropLoader: FC<IBackdropLoaderProps> = ({
  loaderType = 'circular',
  customLoader,
  onClose,
  progress,
}) => {
  const loader = useMemo(() => {
    if (customLoader) return customLoader

    if (loaderType === 'circular') {
      const props = progress
        ? {
            variant: 'determinate' as const,
            value: progress,
          }
        : {}

      return <CircularProgress aria-busy='true' {...props} />
    } else {
      return <LinearProgress aria-busy='true' sx={{ width: '8rem' }} />
    }
  }, [customLoader, loaderType, progress])

  return (
    <Backdrop
      open={true}
      sx={{
        color: '#fff',
        zIndex: 9999999,
      }}
      onClick={onClose}
    >
      {loader}
    </Backdrop>
  )
}
