import { Box, styled, Typography } from '@mui/material'

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.alternativePalette.dark.main,
  marginBottom: theme.spacing(2),
  textTransform: 'capitalize',
}))

export const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.light,
}))

export const ImageBoxWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'center',
  gap: theme.spacing(8),
}))

export const ImageBox = styled(Box)<{ currentimage?: string | null }>(
  ({ theme, currentimage }) => ({
    display: currentimage ? 'block' : 'flex',
    height: '240px',
    width: '240px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    border: currentimage ? '' : `dashed 3px ${theme.alternativePalette.dark.main}`,
    background: currentimage ? `no-repeat url(${currentimage}) center center` : '',
    backgroundSize: 'contain',
    borderRadius: '1em',
    cursor: 'pointer',
    transition: theme.transitions.create('background', {
      duration: '.4s',
    }),

    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  }),
)

export const ImageControlBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '15px',
  minHeight: '100%',
  minWidth: '100%',
  transition: theme.transitions.create('opacity', {
    duration: '.2s',
  }),

  backgroundColor: '#00000088',
  opacity: '0',

  '&:hover': {
    opacity: '1',
  },
}))
