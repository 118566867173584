import { Blocker, History, Transition } from 'history'
import { ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { UNSAFE_NavigationContext, useLocation, useNavigate } from 'react-router-dom'
import { VinciExitDialog } from '~/components/VinciExitLayout/VinciExitLayout'

export function useBlocker(blocker: Blocker, when = true): void {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History

  useEffect(() => {
    if (!when) return

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock()
          tx.retry()
        },
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [navigator, blocker, when])
}

export type TUseCallbackPromptReturn = [boolean, () => void, () => void]

export function useCallbackPrompt(when: boolean): TUseCallbackPromptReturn {
  const navigate = useNavigate()
  const location = useLocation()

  const [showPrompt, setShowPrompt] = useState(false)

  const [lastLocation, setLastLocation] = useState<any>(null)

  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false)
    setLastLocation(null)
  }, [])

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false)
    setConfirmedNavigation(true)
  }, [])

  const handleBlockedNavigation = useCallback(
    (nextLocation: any) => {
      if ((nextLocation?.location?.search ?? '').includes('success=true')) {
        setLastLocation(nextLocation)
        return confirmNavigation()
      }

      if (!confirmedNavigation && nextLocation?.location?.pathname !== location.pathname) {
        setShowPrompt(true)
        setLastLocation(nextLocation)
        return false
      }
      return true
    },
    [confirmedNavigation, location, confirmNavigation],
  )

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname)
    }
  }, [confirmedNavigation, lastLocation, navigate])

  useBlocker(handleBlockedNavigation, when)

  return [showPrompt, confirmNavigation, cancelNavigation]
}

export type TUsePromptReturn = ReactNode

export function usePrompt(when = true, message?: string): TUsePromptReturn {
  const [showPrompt, confirmNavigation, rejectNavigation] = useCallbackPrompt(when)

  return (
    <VinciExitDialog
      message={message}
      open={showPrompt}
      onConfirm={confirmNavigation}
      onReject={rejectNavigation}
    />
  )
}
