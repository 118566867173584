import {
  Box,
  ButtonBase,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from '@mui/material'

export const Wrapper = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(0.75),
  marginBottom: theme.spacing(0.6),
  borderRadius: '0.375rem',
}))

export const ItemWrapper = styled(ListItem)<{ active?: string | null; collapsed?: string | null }>(
  ({ theme, active, collapsed }) => {
    return {
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shortest,
      }),
      display: 'flex',
      alignItems: 'center',
      justifyContent: collapsed ? 'center' : 'initial',
      gap: theme.spacing(4.5),
      /* padding: 0,
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(4), */
      padding: collapsed ? '0' : [0, theme.spacing(4), 0, theme.spacing(6)].join(' '),
      borderRadius: '0.375rem',
      width: '100%',
      cursor: 'pointer',
      backgroundColor: active ? 'rgba(255,255,255,.2)' : 'transparent',
      '&:hover, &:focus': {
        backgroundColor: 'rgba(255,255,255,.2)',
      },
      minHeight: '45px',
    }
  },
)

export const ItemText = styled(ListItemText)<{ active?: boolean }>(({ theme, active }) => ({
  padding: [theme.spacing(3), theme.spacing(4)].join(' '),
  paddingLeft: 0,
  margin: 0,
  userSelect: 'none',
  position: 'relative',
  '&:span': {
    color: theme.palette.common.white,
    fontWeight: active ? 400 : 300,
    fontSize: '0.875rem',
  },
}))

export const ItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 0,
  color: theme.palette.common.white,
}))

export const SectionTitle = styled(Typography)(({ theme }) => ({
  display: 'block',
  color: theme.palette.common.white,
  paddingLeft: theme.spacing(4),
  margin: [theme.spacing(4), 0, theme.spacing(2), theme.spacing(2)].join(' '),
  textTransform: 'uppercase',
  fontWeight: 'bold',
}))

SectionTitle.defaultProps = {
  variant: 'caption',
}

export const CollapseWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(2),
}))

export const CollapseIcon = styled(Icon)<{ active?: string | null }>(({ active, theme }) => ({
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.short,
  }),
  transform: active ? 'rotate(180deg)' : 'rotate(360deg)',
}))
