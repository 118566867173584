import { Shadows } from '@mui/material'

export const shadows = [
  'none',
  'rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem',
  'rgb(26 115 232 / 40%) 0rem 0.875rem 1.625rem -0.75rem, rgb(26 115 232 / 15%) 0rem 0.25rem 1.4375rem 0rem, rgb(26 115 232 / 20%) 0rem 0.5rem 0.625rem -0.3125rem',
  'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  'rgba(0, 0, 0, 0.15) 0px 2px 8px;',
  ...Array(20).fill('none'),
] as Shadows
