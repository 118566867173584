export const typography = {
  h4: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: 1.375,
    letterSpacing: '0.00735em',
  },
  h5: {
    fontSize: '1.2rem',
    fontWeight: 600,
    lineHeight: 1.375,
    letterSpacing: '0.00735em',
  },
  h6: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: '0.02857em',
  },
  body1: {
    fontSize: '0.875rem',
    fontWeight: 300,
    lineHeight: 1.5,
    letterSpacing: '0.02857em',
  },
  button: {
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: 1.4,
    letterSpacing: '0.02857em',
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 300,
    lineHeight: 1.25,
    letterSpacing: '0.03333em',
  },
}
