import { Box, Typography, styled } from '@mui/material'
import { VinciButton } from '../VinciButton'

export const Wrapper = styled(Box)(({ theme }) => ({
  minWidth: theme.breakpoints.values.md,
  minHeight: '400px',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4),
  gap: theme.spacing(4),
}))

export const FilesWrapper = styled(Box)(() => ({
  flex: 1,
  width: '100%',
}))

FilesWrapper.defaultProps = {
  my: 5,
}

export const FileWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid #ccc`,
  '&:last-of-type': {
    borderBottom: 'none',
  },
  padding: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}))

export const FileInfo = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[800],
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),
}))

export const FileActions = styled(Box)(({ theme }) => ({
  minWidth: 'max-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flex: 1,
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}))

export const FileDeleteButton = styled(VinciButton)(() => ({
  minHeight: '1rem',
}))

FileDeleteButton.defaultProps = {
  size: 'small',
  variant: 'outlined',
  color: 'error',
}

export const NoFilesText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  textAlign: 'center',
  color: theme.palette.grey[700],
}))

NoFilesText.defaultProps = {
  variant: 'h6',
}

export const UploadFile = styled(VinciButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}))

UploadFile.defaultProps = {
  color: 'primary',
}
