export const DEBOUNCE_TIME_IN_MS = 500

export const API_URL = import.meta.env.VITE_API_URL
export const SOCKET_API_URL = import.meta.env.VITE_SOCKET_API_URL
export const SOCKET_API_SECRET_KEY = import.meta.env.VITE_SOCKET_API_SECRET_KEY
export const FRESHDESK_API_URL = import.meta.env.VITE_FRESHDESK_DOMAIN
export const FRESHDESK_API_USER = import.meta.env.VITE_FRESHDESK_API
export const FRESHDESK_API_PASSWORD = import.meta.env.VITE_FRESHDESK_PASSWORD
export const LOCAL_STORAGE_ID = '@vinci_eyes_on'
export const STATE_STORAGE_LOCATION = LOCAL_STORAGE_ID + '/web'
export const SF_LOGIN_URL = import.meta.env.VITE_SALESFORCE_LOGIN_URL
export const SF_CALLBACK_URL = import.meta.env.VITE_SALESFORCE_CALLBACK_URL
export const SF_CLIENT_ID = import.meta.env.VITE_SALESFORCE_CLIENT_ID
export const SF_RESPONSE_TYPE = import.meta.env.VITE_SALESFORCE_RESPONSE_TYPE
export const SF_DISPLAY_TYPE = import.meta.env.VITE_SALESFORCE_DISPLAY_TYPE
