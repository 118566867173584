import { Box, styled } from '@mui/material'

export const Wrapper = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}))

export const OverlayWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  pointerEvents: 'none',
  transition: 'all ease-in-out .15s',
  backgroundColor: '#000000cc',
  width: '100%',
  height: '100%',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.white,
  zIndex: 99999999,
}))

export const DefaultOverlayWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))
