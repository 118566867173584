import { ButtonProps } from '@mui/material'
import { FC, ForwardRefExoticComponent } from 'react'
import * as S from './VinciButton.styles'

export type VinciButtonProps = ButtonProps & {
  circular?: boolean
  color?: 'primary' | 'secondary' | 'error'
  component?: ForwardRefExoticComponent<any> | string
  to?: string
  iconOnly?: boolean
}

export const VinciButton: FC<VinciButtonProps> = ({ ...props }) => {
  return <S.Wrapper {...props} color={props.color || 'primary'} />
}
