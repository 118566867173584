import { keyframes, styled } from '@mui/material'

const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`

export const Image = styled('img')<{ duration?: number }>(({ theme, duration }) => ({
  borderRadius: theme.shape.borderRadius,
  animationName: fadeIn,
  animationFillMode: 'backwards',
  animationDuration: `${duration || theme.transitions.duration.short}ms`,
  animationTimingFunction: 'ease-in-out',
}))
