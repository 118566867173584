import { Outlet, useLocation } from 'react-router-dom'

import { Navbar } from '~/components/Navbar'
import { Sidenav } from '~/components/Sidenav'
import { useIsDevice } from '~/hooks/useIsDevice'
import { useUI } from '~/hooks/useUI'
import { getAppVersion } from '~/utils/getAppVersion'
import * as S from './Layout.styles'

const HIDE_NAVBAR_PREFIX = [
  '/login',
  '/register',
  '/audit/fill_report',
  '/assets/',
  /^\/audit\/(?!create\/)[a-zA-Z0-9]+\/$/,
]

export const Layout = () => {
  const location = useLocation()
  const { sidenavOpen, sidenavCollapsed } = useUI()
  const { isMobile } = useIsDevice()

  const navbarVisible = !HIDE_NAVBAR_PREFIX.some((path) => {
    if (typeof path === 'string') {
      return location.pathname.startsWith(path)
    } else if (path instanceof RegExp) {
      return path.test(location.pathname)
    }
    return false
  })

  return (
    <S.Wrapper>
      <S.SidenavWrapper>
        <Sidenav />
      </S.SidenavWrapper>
      <S.ContentWrapper
        issidebaropen={!isMobile ? (sidenavOpen ? `${sidenavOpen}` : null) : null}
        collapsed={!isMobile ? (sidenavCollapsed ? `${sidenavCollapsed}` : null) : null}
      >
        {navbarVisible ? <Navbar /> : null}
        <S.OutletWrapper>
          <Outlet />
        </S.OutletWrapper>
        {APP_NAME && APP_VERSION && (
          <S.VersionWrapper>
            <S.Version>
              {APP_NAME} <span>Version </span>
              {getAppVersion()}
            </S.Version>
          </S.VersionWrapper>
        )}
      </S.ContentWrapper>
    </S.Wrapper>
  )
}
