import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { updateToastInfo } from '~/utils/toast'

interface IRequestProps<T> {
  initialText?: string
  successText?: string
  successCallback?: (response?: any) => void
  errorText?: string
  requestFunction: (props: any) => Promise<any>
  requestProps?: T
  disableToast?: boolean
}

type ApiError = {
  message?: string
}

export const useRequestWithToast = () => {
  const {t} = useTranslation()
  async function request<T>({
    initialText = t('Loading...'),
    successText = t('Success!'),
    successCallback,
    errorText = t('There was an error! Please try again.'),
    requestFunction,
    requestProps,
    disableToast = false,
  }: IRequestProps<T>) {
    const toastId = !disableToast
      ? toast.loading(initialText, {
          autoClose: 10000,
        })
      : 0
    return await requestFunction(requestProps)
      .then((response) => {
        !disableToast && updateToastInfo(toastId, successText, 'success')

        if (successCallback && typeof successCallback === 'function') successCallback(response)

        return response
      })
      .catch((error: AxiosError) => {
        const errorMessage = (error.response?.data as ApiError)?.message
        !disableToast && updateToastInfo(toastId, errorMessage || errorText, 'error')
        return false
      })
  }

  return {
    request,
  }
}

export default useRequestWithToast
