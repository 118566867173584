import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UISlice {
  sidenavOpen?: boolean
  sidenavCollapsed?: boolean
}

const initialState: UISlice = {
  sidenavOpen: true,
  sidenavCollapsed: false,
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleSidenavOpen: (state: UISlice) => {
      state.sidenavOpen = !state.sidenavOpen
    },
    changeSidenavOpen: (state: UISlice, payload: PayloadAction<boolean>) => {
      state.sidenavOpen = payload.payload
    },
    toggleSidenavCollapsed: (state: UISlice) => {
      state.sidenavCollapsed = !state.sidenavCollapsed
    },
    changeSidenavCollapsed: (state: UISlice, payload: PayloadAction<boolean>) => {
      state.sidenavCollapsed = payload.payload
    },
  },
})

export const {
  toggleSidenavOpen,
  toggleSidenavCollapsed,
  changeSidenavCollapsed,
  changeSidenavOpen,
} = uiSlice.actions

export default uiSlice.reducer
